import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import router from './router'
import Modal from "@/plugins/modal";
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css';
import MwAuth from "@/plugins/mw_auth"
import Requester from "@/plugins/requester";

Vue.config.productionTip = false

Vue.use(Modal)
Vue.use(VueToast)

Vue.use(Requester)
Vue.use(MwAuth)

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
