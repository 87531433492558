<template>
    <v-col>
        <div
            class="text-center">
            <v-btn
                color="primary"
                :disabled="dialog"
                @click="exportDocs">
                Exporter les documents de la formation
            </v-btn>

            <v-dialog
                v-model="dialog"
                hide-overlay
                persistent
                width="400">
                <v-card
                    color="primary"
                    dark

                    class="pa-3 pt-5">
                    <v-card-text>
                        Génération des documents en cours
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="m-0"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-col>
</template>

<script>
export default {
    name: "Documents",
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        dialog: false, 
    }),
    methods: {
        exportDocs: async function () {
            this.dialog = true;
            await this.$req
                .get('formations/' + this.formation.uuid + '/export', true, true, {
                    responseType: 'blob',
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Formation.zip');
                    document.body.appendChild(link);
                    link.click();
                })
                .finally(() => { this.dialog = false })
        }
    }
}
</script>