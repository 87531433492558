<template>
    <Feedback feedback-type="company" :ratings="ratings" :full-width="fullWidth">
        <template v-slot:title="{feedback}">
            <span>Feedback entreprise: <strong>{{ feedback.title }}</strong></span>
        </template>

        <template v-slot:beforeRating="{feedback}">
            <div class="pa-3">
                <p>Bonjour,</p>
                <p>Vous avez inscrit un/des collaborateur/s à la session de formation
                    en intra-entreprises dans vos locaux, du {{ new Date(feedback.firstDate).toLocaleDateString() }} sur le sujet
                    de « {{ feedback.formationName }} ».</p>
                <p>Dans le cadre de notre processus d’amélioration qualité, nous aimerions obtenir
                    de votre part un retour sur la manière dont la formation s’est déroulée et vos remarques.</p>
                <p>Votre aide nous permettra de nous améliorer et de satisfaire nos clients.</p>
            </div>
        </template>
        <template v-slot:afterRating="{feedback}">
            <v-divider/>

            <div class="ma-0 pa-3">
                <p class="mb-0 mt-2 body-1">Votre collaborateur vous a-t-il informé sur sa possibilité de contacter notre
                    organisme de
                    formation via son support pour ce qui concerne l’installation du logiciel, tout complément
                    d’informations lié à la bonne utilisation du logiciel et pour toutes questions administratives ?</p>

                <div
                    class="text-center">
                    <v-radio-group
                        v-model="feedback.q_Info"
                        class="d-inline-block"
                        hide-details
                        row>
                        <v-radio label="Oui" :value="true"/>
                        <v-radio label="Non" :value="false"/>
                    </v-radio-group>
                </div>
            </div>
            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_MoreContent"
                    outlined
                    label="Avez-vous eu des retours concernant le déroulé de la formation et si oui lesquelles ?"/>
            </v-row>
        </template>
    </Feedback>
</template>

<script>
import Feedback from "@/components/feedback/Feedback";

export default {
    name: "FeedbackCompany",
    components: {Feedback},
    props: ['fullWidth'],
    data: () => ({
        ratings: [
            {label: 'Sur le plan administratif (devis, convention, documents administratifs, facturation …) avez-vous été satisfait de notre prestation ?', name: 'q_Administratif'},
            {label: 'Les délais de démarrage de la session de formation était-elle satisfaisante ?', name: 'q_Delais'},
            {label: 'La formation correspond-elle au besoin qui avait été exprimé en amont de la formation ?', name: 'q_Besoin'},
            {label: 'Avez-vous pu vérifier si vos collaborateurs ont pu mettre en pratique les compétences professionnelles que nous leur avons transmises durant la formation ?', name: 'q_Pratique'},
            {label: 'De manière générale, êtes-vous satisfait de la formation ?', name: 'q_General'},
        ]
    }),
}
</script>