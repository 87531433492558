<template>
    <div>
        <Crud
            title="Formations proposés"
            new-title="Nouvelle formation"
            uri-rest="listformations"
            :crud-headers="crudHeaders"
            :default-item="defaultItem"
            :disabled="disabled">
            <template scope="{editedItem}">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedItem.name"
                            :rules="[v => !!v || 'Un nom est requis']"
                            label="Nom"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedItem.filename"
                            :rules="[v => !!v || 'Un nom de fichier est requis']"
                            label="Nom de fichier"/>
                    </v-col>
                </v-row>
            </template>
        </Crud>
    </div>
</template>

<script>
import Crud from "@/components/crud/Crud";

export default {
    name: "CrudListFormations",
    props: ['disabled'],
    components: {
        Crud
    },
    data: () => ({
        crudHeaders: [
            {text: 'Nom', value: 'name'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
        defaultItem: {
            name: '',
            filename: '',
        },
    }),
}
</script>