<template>
    <v-container>
        <h2 class="mb-3">Indices de satisfaction</h2>


        <v-row class="text-center" v-if="formations != null">
            <v-col>
                <v-card class="mx-auto" elevation="3" height="100%" max-width="350">
                    <v-card-title class="justify-center">
                        {{ formations.length }} formations au total
                    </v-card-title>

                    <v-card-text>
                        <v-rating
                            :value="satisfactionRating(satifactionAll(formations))"
                            length="4"
                            half-increments
                            readonly/>
                        ({{ satifactionAll(formations) }}/4)
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mx-auto" elevation="3" height="100%" max-width="350">
                    <v-card-title class="justify-center">
                        {{ formationsPenultimateYear.length }} formations durant {{ new Date().getFullYear() - 1 }}
                    </v-card-title>

                    <v-card-text>
                        <v-rating
                            :value="satisfactionRating(satifactionAll(formationsPenultimateYear))"
                            length="4"
                            half-increments
                            readonly/>
                        ({{ satifactionAll(formationsPenultimateYear) }}/4)
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mx-auto" elevation="3" height="100%" max-width="350">
                    <v-card-title class="justify-center">
                        {{ formationsCurrentYear.length }} formations durant {{ new Date().getFullYear() }}
                    </v-card-title>

                    <v-card-text>
                        <v-rating
                            :value="satisfactionRating(satifactionAll(formationsCurrentYear))"
                            length="4"
                            half-increments
                            readonly/>
                        ({{ satifactionAll(formationsCurrentYear) }}/4)
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mx-auto" elevation="3" height="100%" max-width="350">
                    <v-card-title class="justify-center">
                        Dernière formation
                    </v-card-title>

                    <v-card-text>
                        <v-rating
                            :value="satisfactionRating(satifactionAll(formations.slice(0, 1)))"
                            length="4"
                            half-increments
                            readonly/>
                        ({{ satifactionAll(formations.slice(0, 1)) }}/4)
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <h3 class="mt-5 mb-3">Indice de satisfaction par formation</h3>
        <v-data-table
            v-if="formations != null"
            :headers="satifactionFormationsHeaders"
            :items="formations"
            class="elevation-1">
            <template v-slot:item.date="{ item }">
                <span v-if="item.firstDate != null">
                    {{ new Date(item.firstDate).toLocaleString().substr(0, 10) }}
                </span>
            </template>
            <template v-slot:item.satisfactionCandiates="{ item }">
                <span v-if="item.feedbackCandidates != null &&satisfaction(item.feedbackCandidates) >= 0">
                    {{ satisfaction(item.feedbackCandidates) }}/4
                </span>
                <span v-else>∅</span>
            </template>
            <template v-slot:item.satisfactionCompany="{ item }">
                <span v-if="item.feedbackCompany != null && satisfaction(item.feedbackCompany) >= 0">
                    {{ satisfaction(item.feedbackCompany) }}/4
                </span>
                <span v-else>∅</span>
            </template>
            <template v-slot:item.satisfactionFinanceur="{ item }">
                <span v-if="item.feedbackFinanceur != null && satisfaction(item.feedbackFinanceur) >= 0">
                    {{ satisfaction(item.feedbackFinanceur) }}/4
                </span>
                <span v-else>∅</span>
            </template>
            <template v-slot:item.satisfactionInstructor="{ item }">
                <span v-if="item.feedbackInstructor != null && satisfaction(item.feedbackInstructor) >= 0">
                    {{ satisfaction(item.feedbackInstructor) }}/4
                </span>
                <span v-else>∅</span>
            </template>
            <template v-slot:item.global="{ item }">
                <span v-if="global(item) >= 0">
                    {{ global(item) }}/4
                </span>
                <span v-else>∅</span>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
export default {
    name: "Satisfaction",
    data: () => ({
        formations: null,

        satifactionFormationsHeaders: [
            {text: 'Date', value: 'date'},
            {text: 'Entreprise', value: 'company.name'},
            {text: 'Formateur', value: 'instructor.fullname'},
            {text: 'Satisfaction participants', value: 'satisfactionCandiates', sortable: false},
            {text: 'Satisfaction entreprise', value: 'satisfactionCompany', sortable: false},
            {text: 'Satisfaction au financeur', value: 'satisfactionFinanceur', sortable: false},
            {text: 'Satisfaction formateur', value: 'satisfactionInstructor', sortable: false},
            {text: 'Global', value: 'global', sortable: false},
        ],
    }),
    computed: {
        formationsPenultimateYear(){
            return this.formations
                .filter(f => f.firstDate != null && new Date(f.firstDate).getFullYear() === new Date().getFullYear() - 1)
        },
        formationsCurrentYear(){
            return this.formations
                .filter(f => f.firstDate != null && new Date(f.firstDate).getFullYear() === new Date().getFullYear())
        }
    },
    methods: {
        satifactionAll(formations){
            if(formations.length === 0)
                return 0

            let average = 0
            formations.forEach(formation => {
                let g = this.global(formation)
                if (g >= 0) {
                    average += g
                }
            })

            return (Math.round((average / formations.length + Number.EPSILON) * 10) / 10)
        },
        satisfactionRating(average){
            if(average < 0.3)
                return 0
            if(average < 0.8)
                return 0.5
            if(average < 1.3)
                return 1
            if(average < 1.8)
                return 1.5
            if(average < 2.3)
                return 2
            if(average < 2.8)
                return 2.5
            if(average < 3.3)
                return 3
            if(average < 3.8)
                return 3.5
            return 4
        },
        satisfaction(feedback){
            if(feedback != null){
                let average = 0

                if(Array.isArray(feedback)){
                    feedback.forEach(f => {
                        average += f.averageRating
                    })

                    average = average / feedback.length
                }else{
                    average = feedback.averageRating;
                }

                return (Math.round((average + Number.EPSILON) * 10) / 10)
            }

            return 0
        },
        global(formation){
            let sCandidates = this.satisfaction(formation.feedbackCandidates)
            let sCompany = this.satisfaction(formation.feedbackCompany)
            let sFinanceur = this.satisfaction(formation.feedbackFinanceur)
            let sInstructor = this.satisfaction(formation.feedbackInstructor)

            let count = 0
            let sum = 0

            if(sCandidates >= 0){
                count++
                sum += sCandidates
            }
            if(sCompany >= 0){
                count++
                sum += sCompany
            }
            if(sFinanceur >= 0){
                count++
                sum += sFinanceur
            }
            if(sInstructor >= 0){
                count++
                sum += sInstructor
            }

            if(count === 0)
                return -1

            return (Math.round((sum / count + Number.EPSILON) * 10) / 10)
        }
    },
    mounted(){
        this.$req
            .get('formations')
            .then((data) => {
                this.formations = data.filter(e => e.closed && !e.cancelled)
                console.log(this.formations)
            })
            .catch(() => this.loadError = true)
    },
}
</script>

<style scoped>
.v-card__text, .v-card__title {
    word-break: normal;
}
</style>