import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#d98006', // #E53935
                secondary: '#e19938', // #FFCDD2
                accent: '#565656', // #3F51B5
            },
        },
    },
    breakpoint: {
        mobileBreakpoint: 'sm' // This is equivalent to a value of 960
    },
});
