<template>
    <v-col>
        <v-alert
            v-if="formation.quoteAccepted && !formation.closed && !formation.feedbackGenerated"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="2">
            Le devis a été accepté, il n'est par conséquent plus possible d'éditer ces informations.
        </v-alert>
        <v-alert
            v-else-if="formation.quoteAccepted && !formation.closed"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="2">
            Les feedbacks ont déjà été généré, il n'est par conséquent plus possible d'éditer ces informations.
        </v-alert>
        <v-alert
            v-if="$auth.hasManagerRights && !formation.closed && !formation.feedbackGenerated && formation.quoteAccepted && !forceEdition"
            class="text-center"
            type="info"
            color="accent"
            border="bottom"
            colored-border
            elevation="2">
            Accès manager:
            <v-btn light @click="forceEdition = true">
                Éditer le document
            </v-btn>
        </v-alert>
        <div
            v-if="formation == null || candidates == null">
            <v-progress-circular
                indeterminate/>
        </div>
        <v-card
            v-else>
            <v-toolbar
                flat
                color="primary"
                dark>
                <v-toolbar-title>
                    Entreprise
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-card flat>
                    <v-card-title>Nom</v-card-title>
                    <v-card-text>{{ formation.company.name }}</v-card-text>
                </v-card>
                <v-card flat>
                    <v-card-title>Adresse</v-card-title>
                    <v-card-text>{{ address }}</v-card-text>
                </v-card>
                <v-card flat>
                    <v-card-title>Adresse email de contact</v-card-title>
                    <v-card-text>
                        <a :href="'mailto:' + formation.company.contactEmail">{{ formation.company.contactEmail }}</a>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-toolbar
                flat
                color="primary"
                dark>
                <v-toolbar-title>Participants</v-toolbar-title>
                <v-spacer/>
                <v-dialog
                    v-model="participantsDialogEdit"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :loading="companyCandidates == null"
                            :disabled="formation.closed || formation.feedbackGenerated || (formation.quoteAccepted && !forceEdition)"
                            light
                            color="accent"
                            v-bind="attrs"
                            v-on="on">
                            Éditer
                        </v-btn>
                    </template>
                    <v-card>
                        <SelectCandidates v-model="selectedCandidates" :company-uuid="formation.company.uuid"/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="participantsDialogEdit = false">Annuler</v-btn>
                            <v-btn @click="updateCandidates" :loading="updatingCandidate" color="primary">Enregistrer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>

            <v-card-text v-if="candidates.length > 0">
                <v-list>
                    <v-list-item
                        v-for="candidate in candidates"
                        :key="candidate.uuid"
                        two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ candidate.lastname == null ? candidate.lastname : candidate.lastname.toUpperCase() }}
                                {{ candidate.firstname }}
                            </v-list-item-title>
                            <v-list-item-subtitle><a :href="'mailto:' + candidate.email">{{ candidate.email }}</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="candidate.handicap">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="grey lighten-1"
                                        v-bind="attrs"
                                        v-on="on">
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span>
                                    Handicap
                                    <span v-if="candidate.handicapStr != null && candidate.handicapStr !== ''">
                                        : {{ candidate.handicapStr }}
                                    </span>
                                </span>
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import SelectCandidates from "../../../components/models/SelectCandidates";

export default {
    name: "InitialSettings",
    components: {SelectCandidates},
    props: {
        formation: {
            type: Object
        }
    },
    mounted() {
        this.$req
            .get('formations/' + this.formation.uuid + '/candidates')
            .then(response => {
                this.candidates = response
                this.selectedCandidates = response
            })

        this.$req
            .get('companies/' + this.formation.companyUuid + '/candidates')
            .then(data => {
                this.companyCandidates = data
            })
    },
    data: () => ({
        forceEdition: false,

        participantsDialogEdit: false,
        candidates: null,
        selectedCandidates: [],
        companyCandidates: null,
        updatingCandidate: false,
    }),
    computed: {
        address() {
            let a = this.formation.company.address
            return (a.addr1 + ', ' + (a.addr2 ? a.addr2 + ', ' : '') + a.zip + ' ' + a.city + ', ' + a.country)
        }
    },
    methods: {
        updCandidates: async function (candidatesSource, candidatesTarget, uri) {
            await this.$req
                .get('companies/' + this.formation.companyUuid + '/candidates')
                .then(data => {
                    this.companyCandidates = data
                })

            if(candidatesSource == null){
                candidatesSource = [];
            }
            if(candidatesTarget == null){
                candidatesTarget = [];
            }

            for (const item of candidatesSource) {
                console.log(item)
                if (!candidatesTarget.includes(item)) {
                    await this.$req.del('formations/' + this.formation.uuid +
                        '/' + uri + '/' + item, false)
                        .catch(error => {
                            this.$toast.error(error)
                        })
                }
            }
            for (const item of candidatesTarget) {
                if (!candidatesSource.includes(item)) {
                    await this.$req.put('formations/' + this.formation.uuid +
                        '/' + uri + '/' + item, {}, false)
                        .catch(error => {
                            this.$toast.error(error)
                        })
                }
            }
        },
        updateCandidates: async function() {
            this.updatingCandidate = true
            this.selectedCandidates = this.selectedCandidates.map(c => c.uuid == null ? c : c.uuid)
            await this.updCandidates(this.candidates.map(i => i.uuid), this.selectedCandidates, 'candidates')
            this.candidates = this.companyCandidates.filter(c => this.selectedCandidates.includes(c.uuid))
            this.participantsDialogEdit = false
            this.updatingCandidate = false
        }
    }
}
</script>