<template>
    <div
        v-if="!loaded"
        class="text-center">
        <v-progress-circular
            color="primary"
            indeterminate/>
    </div>
    <div
        class="text-center"
        v-else-if="loaded && loadError">
        <v-alert
            class="d-inline-block"
            type="warning">
            Une erreur est survenu durant le chargement des données.
        </v-alert>
    </div>
    <v-col v-else>
        <v-alert
            v-if="false"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="2">
            Le devis a été accepté, il n'est par conséquent plus possible d'éditer ces informations.
        </v-alert>
        <v-form
            :disabled="formation.closed">

            <v-textarea
                v-model="questions.analyseAleas"
                label="Analyse des aléas survenus durant la formation:"
                rows="4"
                auto-grow
                outlined/>

            <v-textarea
                v-model="questions.analyseDifficulte"
                label="Analyse des difficultés rencontrées durant la formation:"
                rows="4"
                auto-grow
                outlined/>

            <v-textarea
                v-model="questions.analyseRetours"
                label="Analyse des retours, des réclamations reçus pour cette session de formation:"
                rows="4"
                auto-grow
                outlined/>

            <v-textarea
                v-model="questions.actionsCorrectives"
                label="Actions correctives suite à l'analyse des retours sollicités auprès de nos partenaires, des aléas, difficultés et réclamations:"
                rows="4"
                auto-grow
                outlined/>

            <v-btn
                color="primary"
                :disabled="formation.closed"
                @click="save">
                Enregistrer
            </v-btn>
        </v-form>
    </v-col>
</template>

<script>
export default {
    name: "Synthese",
    props:{
        formation:{
            type: Object
        }
    },
    data: () => ({
        loadError: false,
        loaded: false,

        questions: {
            analyseAleas: '',
            analyseDifficulte: '',
            analyseRetours: '',
            actionsCorrectives: '',
        },
    }),
    methods:{
        save: async function() {
            if(this.formation.synthese != null) {
                this.questions.uuid = this.formation.syntheseUuid

                await this.$req
                    .put('synthese/' + this.formation.syntheseUuid, this.questions)
                    .then(() => {
                        this.$req
                            .get('synthese/' + this.formation.syntheseUuid)
                            .then(response => {
                                this.formation.synthese = response
                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            }else {
                await this.$req
                    .post('synthese', this.questions)
                    .then(response => {
                        this.formation.syntheseUuid = response.uuid
                        this.formation.synthese = response

                        this.$req
                            .put('formations/' + this.formation.uuid, this.formation)
                            .then(() => {
                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            }
        }
    },
    mounted: async function() {
        if(this.formation.syntheseUuid != null) {
            await this.$req
                .get('synthese/' + this.formation.syntheseUuid)
                .then(response => {
                    this.questions = response
                    this.loaded = true;
                })
                .catch(() => {
                    this.loaded = true
                    this.loadError = true
                })
        }else{
            this.loaded = true
        }
    }
}
</script>