<template>
    <div>
        <v-row dense>
            <v-col dense>
                <v-text-field
                    v-model="editedItem.name"
                    label="Nom"
                    :rules="[v => !!v || 'Un nom est requis']"/>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col dense>
                <v-text-field
                    v-model="editedItem.contactEmail"
                    label="Contact (email)"
                    :rules="[v => !!v || 'Une adresse email est requise']"/>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                cols="12"
                sm="6">
                <v-text-field
                    v-model="editedItem.address.addr1"
                    label="Adresse 1"
                    :rules="[v => !!v || 'Une addresse est requise']"/>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-text-field
                    v-model="editedItem.address.addr2"
                    label="Adresse 2"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6">
                <v-text-field
                    v-model="editedItem.address.zip"
                    label="Code postal"
                    :rules="[v => !!v || 'Un code postal est requis', v => (v && v > 0) || 'Le code postal ne doit pas être égal à 0']"/>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-text-field
                    v-model="editedItem.address.city"
                    label="Ville"
                    :rules="[v => !!v || 'Un nom de ville est requis']"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="editedItem.address.country"
                    label="Pays"
                    :rules="[v => !!v || 'Un pays est requis']"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="editedItem.siren"
                    label="N° Siren"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "TmplCompanies",
    props: {
        editedItem: {
            type: Object
        }
    }
}
</script>