<template>
    <v-container class="home">
        <h2>{{ this.$auth.displayName }}</h2>

        <v-divider class="my-3"/>

        <div v-if="instructors == null || formations == null" class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate/>
        </div>

        <div v-else>
            <v-select
                v-if="instructors != null && formations != null"
                v-model="selectedInstructor"
                :items="instructors"
                item-value="uuid"
                label="Formateur">
                <template v-slot:selection="data">
                    {{ data.item.fullname }}
                </template>
                <template v-slot:item="data">
                    {{ data.item.fullname }}
                </template>
            </v-select>

            <v-row>
                <v-col>
                    <FormationsOpen :formations="instructorFormations"/>
                </v-col>
                <v-col>
                    <RecentFeedback :formation="lastClosedFormation"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <FormationsClose :formations="instructorFormationsClosed"/>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import FormationsOpen from "@/components/home/FormationsOpen";
import RecentFeedback from "@/components/home/RecentFeedback";
import FormationsClose from "@/components/home/FormationsClose";
export default {
    name: 'Home',
    components: {FormationsClose, RecentFeedback, FormationsOpen},
    data: () => ({
        loadError: false,
        instructors: null,
        selectedInstructor: null,

        formations: null,
        formationsClosed: null,
    }),
    computed: {
        instructorFormations() {
            if (this.selectedInstructor.isAll != null) {
                return this.formations
            }

            console.log(this.selectedInstructor)
            console.log(this.formations)
            return this.formations.filter(e => e.instructorUuid === this.selectedInstructor)
        },
        instructorFormationsClosed() {
            if (this.selectedInstructor.isAll != null) {
                return this.formationsClosed
            }

            return this.formationsClosed.filter(e => e.instructorUuid === this.selectedInstructor)
        },
        lastClosedFormation() {
            if (this.selectedInstructor.isAll != null) {
                return this.formationsClosed.slice(0, 1)[0]
            }

            return this.formationsClosed.filter(e => e.instructorUuid === this.selectedInstructor).slice(0, 1)[0]
        }
    },
    mounted() {
        this.$req
            .get('instructors')
            .then((data) => {
                this.instructors = data

                let instructorAll = {
                    isAll: true,
                    fullname: 'Tous'
                }
                this.selectedInstructor = instructorAll
                this.instructors.unshift(instructorAll)

                this.$req
                    .get('formations')
                    .then((data) => {
                        this.formations = data.filter(e => !e.closed)
                        this.formationsClosed = data.filter(e => e.closed).slice(0, 3)
                    })
                    .catch(() => this.loadError = true)
            })
    }
}
</script>
