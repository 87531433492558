<template>
    <div>
        <v-app-bar
            v-if="$vuetify.breakpoint.mobile"
            color="accent"
            dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>Menu</v-toolbar-title>
        </v-app-bar>

        <div>
            <div
                class="text-center"
                v-if="!loaded">
                <v-progress-circular
                    color="primary"
                    indeterminate/>
            </div>
            <v-row class="mt-0">
                <v-col
                    class="ma-0 pa-0"
                    cols="auto">
                    <v-navigation-drawer
                        :app="$vuetify.breakpoint.mobile"
                        v-model="showDrawer">
                        <v-tabs
                            vertical>
                            <div
                                v-for="(route, index) in routes"
                                :key="index">
                                <v-divider v-if="route.divider"/>
                                <v-tab
                                    v-else
                                    :to="{name: route.to}"
                                    :disabled="route.disabled">
                                    {{ route.label }}
                                </v-tab>
                            </div>
                        </v-tabs>
                    </v-navigation-drawer>
                </v-col>
                <v-col>
                    <v-col
                        v-if="formation != null"
                        cols="12"
                        lg="8"
                        offset-lg="2"
                        xl="6"
                        offset-xl="3">
                        <v-alert
                            v-if="formation.closed"
                            type="info"
                            class="text-center"
                            border="top"
                            colored-border
                            color="secondary"
                            dense
                            elevation="3">
                            Aucune édition possible car la formation a été clôturé
                        </v-alert>

                        <router-view :formation="formation" @updated="updateRoutes"/>
                    </v-col>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>


export default {
    name: "FormationDetail",
    components: {},
    data: () => ({
        drawer: false,

        loaded: false,
        formation: null,

        routes: [],
        quotationAccepted: false,
    }),
    computed: {
        showDrawer: {
            get: function () {
                return this.drawer || !this.$vuetify.breakpoint.mobile
            },
            set: function (val) {
                this.drawer = val
            }
        },
    },
    methods: {
        updateRoutes() {
            this.routes = [
                {to: 'FormationDetail.Checklist', label: 'Check-list'},
                {to: 'FormationDetail.Notes', label: 'Notes'},
                {to: 'FormationDetail.Informations', label: 'Informations'},
                {to: 'FormationDetail.InitialChips', label: 'Entretien initial'},
                {to: 'FormationDetail.Internal', label: 'Interne'},
                {to: 'FormationDetail.Quotation', label: 'Devis'},
                {divider: true},
                {to: 'FormationDetail.Confirmation', label: 'Confirmation', disabled: !this.formation.quoteAccepted},
                {to: 'FormationDetail.LocauxEquipMwf', label: 'Locaux équip. MWF', disabled: !this.formation.quoteAccepted},
                {to: 'FormationDetail.Feedbacks', label: 'Feedbacks', disabled: !this.formation.quoteAccepted},
                {to: 'FormationDetail.Synthese', label: 'Synthèse', disabled: !this.formation.quoteAccepted},
                {divider: true},
                {to: 'FormationDetail.Documents', label: 'Documents'},
                {to: 'FormationDetail.Close', label: 'Clôture'},
            ]
        }
    },
    mounted() {
        this.$req.get('formations/' + this.$route.params.uuid)
            .then(data => {
                this.formation = data
                this.quotationAccepted = this.formation.quoteAccepted
                this.loaded = true

                this.updateRoutes()
            })
    },
}
</script>