<template>
    <div>
        <Crud
            title="Formateurs"
            new-title="Nouveau formateur"
            uri-rest="instructors"
            :crud-headers="crudHeaders"
            :default-item="defaultItem"
            :disabled="disabled">
            <template scope="{editedItem}">
                <v-row>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-text-field
                            v-model="editedItem.lastname"
                            :rules="[v => !!v || 'Un nom est requis']"
                            label="Nom"/>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-text-field
                            v-model="editedItem.firstname"
                            :rules="[v => !!v || 'Un prénom est requis']"
                            label="Prénom"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedItem.title"
                            :rules="[v => !!v || 'Une fonction est requise']"
                            label="Fonction"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedItem.certificateFilename"
                            :rules="[v => !!v || 'Un nom de fichier est requis']"
                            label="Nom du fichier pour le certificat"/>
                    </v-col>
                </v-row>
            </template>
        </Crud>
    </div>
</template>

<script>
import Crud from "@/components/crud/Crud";

export default {
    name: "CrudInstructors",
    props: ['disabled'],
    components: {
        Crud
    },
    data: () => ({
        crudHeaders: [
            {text: 'Nom', value: 'lastname'},
            {text: 'Prénom', value: 'firstname'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
        defaultItem: {
            lastname: '',
            firstname: '',
            title: '',
            certificateFilename: '',
        },
    }),
}
</script>