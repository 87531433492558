import AppModal from "@/components/AppModal";

const Modal = {
    install(Vue) {
        this.EventBus = new Vue()
        Vue.component('app-modal', AppModal)

        Vue.prototype.$modal = {
            show(params) {
                Modal.EventBus.$emit('show', params)
            },
            showError(error){
                Modal.EventBus.$emit('showError', error)
            }
        }
    }
}

export default Modal