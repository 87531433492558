<template>
    <v-expansion-panel
        class="mb-5"
        elevation="3"
        :disabled="disabled">
        <v-expansion-panel-header>
            <v-checkbox
                class="ma-0"
                :label="title"
                :input-value="checkboxes.every(cb => cb.value)"
                hide-details
                readonly/>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row class="ma-0" v-for="cb in checkboxes"
                   :key="cb.title">
                <div class="ma-0 pa-0">
                    <v-checkbox
                        v-model="cb.value"
                        :label="cb.title"
                        @click="check(cb, cb.checklistAttr)"
                        :readonly="cb.checklistAttr == null"
                        :disabled="cb.checklistAttr == null || disabled"
                        :hint="cb.action"
                        persistent-hint
                        :hide-details="cb.action == null"/>
                </div>
                <v-spacer/>
                <div class="align-self-center" v-if="cb.validatedBy != null">
                    Validé par {{ cb.validatedBy }}
                </div>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "ChecklistGroup",
    props: [
        'title',
        'validation',
        'validated',
        'checkboxes',
        'disabled'
    ],
    methods: {
        check(data, checklistAttr){
            if(checklistAttr == null){
                return
            }

            if(data.value){
                data.validatedBy = this.$auth.displayName
            }else{
                data.validatedBy = null
            }

            this.$emit('check', checklistAttr, data.validatedBy)
        }
    }
}
</script>

<style scoped>

</style>