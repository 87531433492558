<template>
    <v-col>
        <div
            v-if="formation.quoteSended && !formation.quoteAccepted"
            class="mb-10">
            <v-alert
                class="text-center"
                type="info"
                color="accent"
                border="bottom"
                colored-border
                elevation="2">
                Accès manager:
                <v-btn @click="cancelQuoteSended" light>
                    Annuler l'envoi du devis
                </v-btn>
            </v-alert>
        </div>

        <v-card>
            <v-toolbar
                flat
                color="primary"
                dark>
                <v-toolbar-title>État du devis</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <div v-if="formation.quoteAccepted">
                    <ul>
                        <li>Le devis a été accepté</li>
                    </ul>
                </div>
                <div v-else-if="formation.quoteSended">
                    <ul>
                        <li>Le devis a été envoyé</li>
                    </ul>
                </div>
                <div v-else>
                    <ul>
                        <li>Le devis n'a pas encore été envoyé</li>
                    </ul>
                </div>
            </v-card-text>

            <v-toolbar
                v-if="!formation.quoteAccepted"
                class="mt-6"
                flat
                color="primary"
                dark>
                <v-toolbar-title>Actions</v-toolbar-title>

                <v-spacer/>
                <v-dialog
                    v-if="!formation.quoteSended"
                    v-model="quoteHelpDialog"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            light
                            color="accent"
                            v-bind="attrs"
                            v-on="on">
                            Aide pour l'envoi du devis
                        </v-btn>
                    </template>
                    <v-card
                        :disabled="formation.quoteSended">
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>Aide pour l'envoi du devis</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-text-field
                                v-model="contactEmail"
                                label="Email de contact"/>

                            <v-textarea
                                v-model="emailContent"
                                rows="6"
                                label="Contenu de l'email"/>

                            <v-divider/>

                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Les documents suivant doivent également être envoyé
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-list>
                                            <v-list-item
                                                v-for="(document, index) in documents"
                                                :key="index"
                                                two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ document.name }}</v-list-item-title>
                                                    <v-list-item-subtitle><strong>{{ document.num }}</strong>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="quoteHelpDialog = false">
                                Fermer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>

            <v-card-text
                v-if="!formation.quoteAccepted"
                class="text-center">
                <v-btn
                    v-if="!formation.quoteSended"
                    color="accent"
                    @click="quoteSended">
                    Le devis a été envoyé
                </v-btn>
                <v-btn
                    v-else-if="!formation.quoteAccepted"
                    color="accent"
                    :disabled="formation.quoteAccepted || !formation.quoteSended"
                    @click="acceptQuote">
                    Le devis a été accepté
                </v-btn>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "SendQuotation",
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        quoteHelpDialog: false,

        contactEmail: '',
        emailContent: '',
        documents: [
            {name: 'Modalités administratives et pédagogiques', num: '1.1'},
            {name: 'Programmes de formations', num: '1.1'},
            {name: 'Conditions générales de vente', num: '6.23'},
            {name: 'Règlement intérieur', num: '3.9'},
        ]
    }),
    created() {
        this.contactEmail = this.formation.company.contactEmail
        this.emailContent = 'Madame, Monsieur,\n\n' +
            'En annexe, vous trouverez également les documents selon le référentiel QUALIOPI:\n' +
            '•\tModalités administratives et pédagogiques\n' +
            '•\tConditions générales de vente\n' +
            '•\tRèglement intérieur\n' +
            '•\tLe programme de formation proposé ci-dessous\n\n' +
            'Veuillez agréer, Madame, Monsieur, nos meilleures salutations.\nMW France Sàrl'
    },
    methods: {
        sendQuote() {
            this.quoteSended()
        },
        quoteSended() {
            let formation = this.formation
            formation.quoteSended = true
            this.$req
                .put('formations/' + this.formation.uuid, formation)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                    this.formation.quoteSended = true
                    this.$emit('updated')
                })
        },
        cancelQuoteSended(){
            let formation = this.formation
            formation.quoteSended = false
            this.$req
                .put('formations/' + this.formation.uuid, formation)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                    this.formation.quoteSended = false
                    this.$emit('updated')
                })
        },
        acceptQuote() {
            let formation = this.formation
            formation.quoteAccepted = true
            this.$req
                .put('formations/' + this.formation.uuid, formation)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                    this.formation.quoteAccepted = true
                    this.$emit('updated')
                })
        }
    },
}
</script>