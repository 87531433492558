<template>
    <div
        v-if="!loaded"
        class="text-center">
        <v-progress-circular
            color="primary"
            indeterminate/>
    </div>
    <div
        class="text-center"
        v-else-if="loaded && loadError">
        <v-alert
            class="d-inline-block"
            color="primary"
            type="warning">
            Une erreur est survenu durant le chargement des données.
        </v-alert>
    </div>
    <v-col v-else>
        <v-alert
            v-if="formation.quoteAccepted && !formation.closed"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="2">
            Le devis a été accepté, il n'est par conséquent plus possible d'éditer ces informations.
        </v-alert>
        <v-alert
            v-if="$auth.hasManagerRights && !formation.closed && formation.quoteAccepted && !forceEdition"
            class="text-center"
            type="info"
            color="accent"
            border="bottom"
            colored-border
            elevation="2">
            Accès manager:
            <v-btn light @click="forceEdition = true">
                Éditer le document
            </v-btn>
        </v-alert>
        <v-form
            :disabled="!forceEdition && formation.quoteAccepted">
            <v-select
                v-model="formationsSeen"
                label="Formations vues"
                :items="listFormations"
                item-text="name"
                item-value="uuid"
                chips
                deletable-chips
                multiple/>

            <v-select
                v-model="formationsProposed"
                label="Formations proposés"
                :items="listFormations"
                item-text="name"
                item-value="uuid"
                chips
                deletable-chips
                multiple/>

            <v-combobox
                v-model="formationsType"
                :items="formationTypes"
                label="Type de formation"
                hint="Il est possible d'écrire une valeur personnalisé"
                persistent-hint
                chips
                deletable-chips
                multiple/>

            <v-textarea
                class="mt-6"
                v-model="questions.machines"
                label="Quelles machines avez-vous ?"
                rows="1"
                auto-grow/>

            <v-textarea
                v-model="questions.machinesAxes"
                label="Nombres d'axes sur les machines ?"
                rows="1"
                auto-grow/>

            <v-textarea
                v-model="questions.typePieces"
                label="Quelles types de pièces voulez-vous programmer ?"
                rows="1"
                auto-grow/>

            <v-textarea
                v-model="questions.typeFichiers"
                label="Depuis quels fichiers voulez-vous programmer ?"
                rows="1"
                auto-grow/>

            <v-checkbox
                v-model="questions.coursStd"
                label="Voulez-vous un cours standard ?"/>
            <v-textarea
                v-model="questions.coursNonStdStr"
                :disabled="questions.coursStd"
                label="Si non:"
                rows="1"
                dense
                auto-grow/>

            <v-checkbox
                v-model="questions.ppFonctionnel"
                label="Avez-vous des PP fonctionnels ?"/>
            <v-textarea
                v-model="questions.ppFonctionnelStr"
                :disabled="!questions.ppFonctionnel"
                label="Si oui, lesquelles:"
                rows="1"
                dense
                auto-grow/>

            <v-textarea
                v-model="questions.commentaireFormateur"
                class="mt-2"
                label="Commentaire du formateur"
                rows="3"
                outlined
                auto-grow/>

            <div v-if="questions.candidates.length > 0" class="my-3">
                <v-card>
                    <v-card-title>Informations participants</v-card-title>
                    <v-card-text>
                        <v-tabs v-model="tab">
                            <v-tab v-for="candidate in questions.candidates" :key="candidate.candidateUuid">
                                {{ candidate.candidate.fullname }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="candidate in questions.candidates" :key="candidate.candidateUuid">
                                <v-card flat :disabled="!forceEdition && formation.quoteAccepted">
                                    <v-card-text>
                                        <FormRating v-model="candidate.connInfo"
                                                    label="Connaissances informatiques (Navigation Windows, raccourcis clavier, utilisation de la souris, etc.)"/>

                                        <FormRating v-model="candidate.connDessinTech"
                                                    label="Connaissances techniques (Techniques d'usinage et dessin)"/>

                                        <FormRating v-model="candidate.connCfao"
                                                    label="Connaissances du logiciel ALPHACAM ou autre logiciel de CFAO"/>

                                        <v-textarea
                                            v-model="candidate.connCfaoStr"
                                            label="Si autre logiciel, précisez lequel"
                                            rows="1"
                                            auto-grow/>

                                        <FormRating v-model="candidate.connIso" label="Connaissances du code ISO"/>

                                        <FormRating v-model="candidate.connCnc" label="Connaissances de CNC"/>

                                        <v-textarea
                                            v-model="candidate.connCncStr"
                                            label="Précisez lesquelles"
                                            rows="1"
                                            auto-grow/>

                                        <v-checkbox
                                            v-model="candidate.coursSuivi"
                                            label="Le participant a déjà suivi des cours ALPHACAM (dispensés par MW France Sàrl ou autre organisme de formation) ?"/>
                                        <v-textarea
                                            v-model="candidate.coursSuiviStr"
                                            :disabled="!candidate.coursSuivi"
                                            label="Si oui, préciser lesquels"
                                            rows="1"
                                            auto-grow/>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </div>

            <v-expansion-panels class="mb-5">
                <v-expansion-panel>
                    <v-expansion-panel-header>Anciennes données</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-checkbox
                            v-model="questions.connInfo"
                            :disabled="true"
                            label="Avez-vous des connaissances informatiques ?"/>

                        <v-checkbox
                            v-model="questions.connDessinTech"
                            :disabled="true"
                            label="Avez-vous des connaissances de dessin techniques ?"/>

                        <v-checkbox
                            v-model="questions.connUsinage"
                            :disabled="true"
                            label="Avez-vous des connaissances en usinage ?"/>

                        <v-checkbox
                            v-model="questions.connCommandesCNC"
                            :disabled="true"
                            label="Avez-vous des connaissances de commande CNC ?"/>
                        <v-textarea
                            v-model="questions.connCommandesCNCStr"
                            :disabled="true"
                            label="Si oui, lesquelles:"
                            rows="1"
                            auto-grow/>

                        <v-checkbox
                            v-model="questions.connParamTraj"
                            :disabled="true"
                            label="Avez-vous des connaissances sur le paramétrage des outils et des trajectoires ?"
                            hint="Exemple: F, G41, G42"
                            persistent-hint/>

                        <v-checkbox
                            v-model="questions.coursSuivi"
                            :disabled="true"
                            label="Avez-vous déjà suivi des cours chez nous ou ailleurs ?"/>
                        <v-textarea
                            v-model="questions.coursSuiviStr"
                            :disabled="true"
                            label="Si oui, lesquels:"
                            rows="1"
                            auto-grow/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-btn
                color="primary"
                :disabled="formation.quoteAccepted && !forceEdition"
                @click="save">
                Enregistrer
            </v-btn>
        </v-form>
    </v-col>
</template>

<script>
import FormRating from "@/components/formation-details/FormRating.vue";

export default {
    name: "InitialQuestions",
    components: {FormRating},
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        tab: 0,

        loadError: false,
        loaded: false,
        listFormations: null,
        forceEdition: false,

        // candidates: [],

        formationsSeen: [],
        formationsProposed: [],
        formationsType: [],

        questions: {
            typeFormation: '',
            machines: '',
            machinesAxes: '',
            typePieces: '',
            typeFichiers: '',

            connInfo: false,
            connDessinTech: false,
            connUsinage: false,
            connCommandesCNC: false,
            connCommandesCNCStr: '',
            connParamTraj: false,

            coursStd: false,
            coursNonStdStr: '',
            coursSuivi: false,
            coursSuiviStr: '',
            ppFonctionnel: false,
            ppFonctionnelStr: '',

            commentaireFormateur: '',

            candidates: [],
        },
        questionsCandidate: [],

        formationTypes: [
            'Milling',
            'Turn',
            'Router',
            'Electro',
            'Laser',
            'Designer',
        ]
    }),
    methods: {
        candidateName(candidateUuid) {
            for (const c of this.candidates) {
                if (c.uuid === candidateUuid) {
                    return c.fullname
                }
            }
            return 'Undefined'
        },
        updateFormations(formationsSource, formationsTarget, uri) {
            if (formationsSource == null) {
                formationsSource = [];
            }
            if (formationsTarget == null) {
                formationsTarget = [];
            }

            formationsSource.forEach((item) => {
                if (!formationsTarget.includes(item.listFormationUuid)) {
                    this.$req.del('initialchips/' + this.formation.initialChipsUuid +
                        '/' + uri + '/' + item.listFormationUuid, false)
                        .catch(error => {
                            this.$toast.error(error)
                        })
                }
            })
            formationsTarget.forEach(item => {
                if (!formationsSource.map(v => v.listFormationUuid).includes(item)) {
                    this.$req.put('initialchips/' + this.formation.initialChipsUuid +
                        '/' + uri + '/' + item, {}, false)
                        .catch(error => {
                            this.$toast.error(error)
                        })
                }
            })
        },
        save: async function () {
            if(this.formationsType.length > 0) {
                this.questions.typeFormation = this.formationsType.join(';')
            }else{
                this.questions.typeFormation = ''
            }
            if (this.formation.initialChipsUuid != null) {
                this.questions.uuid = this.formation.initialChipsUuid

                await this.$req
                    .put('initialchips/' + this.formation.initialChipsUuid, this.questions)
                    .then(() => {
                        this.$req
                            .get('initialchips/' + this.formation.initialChipsUuid)
                            .then(response => {
                                this.formation.initialChips = response
                                this.updateFormations(response.formationsSeen, this.formationsSeen, 'formationsSeen')
                                this.updateFormations(response.formationsProposed, this.formationsProposed, 'formationsProposed')

                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            } else {
                await this.$req
                    .post('initialchips', this.questions)
                    .then(response => {
                        this.formation.initialChipsUuid = response.uuid
                        this.formation.initialChips = response

                        this.$req
                            .put('formations/' + this.formation.uuid, this.formation)
                            .then(() => {
                                this.updateFormations(response.formationsSeen, this.formationsSeen, 'formationsSeen')
                                this.updateFormations(response.formationsProposed, this.formationsProposed, 'formationsProposed')

                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            }
        }
    },
    mounted: async function () {
        /*await this.$req
            .get('formations/' + this.formation.uuid + '/candidates')
            .then(response => {
                this.candidates = response
            })
            .catch(() => {
                this.loaded = true
                this.loadError = true
            })*/

        if (this.formation.initialChipsUuid != null) {
            await this.$req
                .get('initialchips/' + this.formation.initialChipsUuid)
                .then(response => {
                    this.questions = response
                    this.formationsSeen = response.formationsSeen.map(v => v.listFormationUuid)
                    this.formationsProposed = response.formationsProposed.map(v => v.listFormationUuid)
                    // this.questionsCandidate = response.candidates.map(v => v.)
                    if (response.typeFormation != null && response.typeFormation !== '')
                        this.formationsType = response.typeFormation.split(';')
                    else
                        this.formationsType = ''
                })
                .catch((err) => {
                    console.log(err)
                    this.loaded = true
                    this.loadError = true
                })
        }

        if (!this.loadError) {
            this.$req
                .get('listformations')
                .then(response => {
                    this.listFormations = response
                    this.loaded = true
                })
                .catch(() => {
                    this.loaded = true
                    this.loadError = true
                })
        } else {
            this.loaded = true
        }
    }
}
</script>