<template>
    <div>
        <h3>Formations récentes</h3>
        <small class="accent--text">Seuls les trois dernières formations clôturé sont affichés</small>

        <div
            v-if="formations != null && formations.length > 0">
            <v-row class="justify-center">
                <v-col
                    v-for="formation in formations"
                    :key="formation.uuid"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="4">
                    <FormationCard
                        :formation="formation"/>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-alert type="info" color="accent" class="my-2">
                Aucune formation disponible
            </v-alert>
        </div>
    </div>
</template>

<script>
import FormationCard from "@/components/FormationCard";
export default {
    name: "FormationsClose",
    components: {FormationCard},
    props: ['formations']
}
</script>