<template>
    <div
        v-if="!loaded"
        class="text-center">
        <v-progress-circular
            color="primary"
            indeterminate/>
    </div>
    <div
        class="text-center"
        v-else-if="loaded && loadError">
        <v-alert
            class="d-inline-block"
            type="warning">
            Une erreur est survenu durant le chargement des données.
        </v-alert>
    </div>
    <v-col v-else>
        <v-alert
            v-if="false"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="2">
            Le devis a été accepté, il n'est par conséquent plus possible d'éditer ces informations.
        </v-alert>
        <v-form
            :disabled="formation.closed">
            <v-checkbox
                hide-details
                v-model="questions.tailleSalleConforme"
                label="Taille de la salle conforme au nombre de stagiaires"/>
            <v-text-field
                v-model="questions.tailleSalleConforme_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.nombreTableSuffisant"
                label="Nombre de tables suffisantes"/>
            <v-text-field
                v-model="questions.nombreTableSuffisant_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.nombreChaiseSuffisant"
                label="Nombre de chaises suffisantes"/>
            <v-text-field
                v-model="questions.nombreChaiseSuffisant_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.fonctionnementElectriqueOk"
                label="Fonctionnement électrique OK (lumière, vidéoprojecteur, prise électrique, ordinateur)"/>
            <v-text-field
                v-model="questions.fonctionnementElectriqueOk_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presenceVideoProjection"
                label="Présence d'un système de vidéo projection"/>
            <v-text-field
                v-model="questions.presenceVideoProjection_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presenceEcranTV"
                label="Présence d'un écran TV suffisamment grand ou d'un mur blanc"/>
            <v-text-field
                v-model="questions.presenceEcranTV_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presencePaperBoard"
                label="Présence d'un paperboard"/>
            <v-text-field
                v-model="questions.presencePaperBoard_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presenceFeutresPaperboard"
                label="Présence de feutres pour paperboard (2 couleurs minimum)"/>
            <v-text-field
                v-model="questions.presenceFeutresPaperboard_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presencePrise"
                label="Présence prise pour branchement ordinateur formateur"/>
            <v-text-field
                v-model="questions.presencePrise_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.presenceOrdinateurs"
                label="Présence d'ordinateurs en nombre suffisant en cas de formation nécessitant des manipulations sur supports informatiques"/>
            <v-text-field
                v-model="questions.presenceOrdinateurs_Remarque"
                label="Commentaire"/>
            <v-divider/>

            <v-checkbox
                hide-details
                v-model="questions.accessoiresStagiaire"
                label="Préconisation à faire au client : feuilles+stylo+bouteille d'eau par stagiaire"/>
            <v-text-field
                v-model="questions.accessoiresStagiaire_Remarque"
                label="Commentaire"/>

            <v-btn
                color="primary"
                :disabled="formation.closed"
                @click="save">
                Enregistrer
            </v-btn>
        </v-form>
    </v-col>
</template>

<script>
export default {
    name: "LocauxEquipMwf",
    props:{
        formation:{
            type: Object
        }
    },
    data: () => ({
        loadError: false,
        loaded: false,

        questions: {
            tailleSalleConforme: false,
            tailleSalleConforme_Remarque: "",
            nombreTableSuffisant: false,
            nombreTableSuffisant_Remarque: "",
            nombreChaiseSuffisant: false,
            nombreChaiseSuffisant_Remarque: "",
            fonctionnementElectriqueOk: false,
            fonctionnementElectriqueOk_Remarque: "",
            presenceVideoProjection: false,
            presenceVideoProjection_Remarque: "",
            presenceEcranTV: false,
            presenceEcranTV_Remarque: "",
            presencePaperBoard: false,
            presencePaperBoard_Remarque: "",
            presenceFeutresPaperboard: false,
            presenceFeutresPaperboard_Remarque: "",
            presencePrise: false,
            presencePrise_Remarque: "",
            presenceOrdinateurs: false,
            presenceOrdinateurs_Remarque: "",
            accessoiresStagiaire: false,
            accessoiresStagiaire_Remarque: "",
        },
    }),
    methods:{
        save: async function() {
            if(this.formation.locauxEquipMwfUuid != null) {
                this.questions.uuid = this.formation.locauxEquipMwfUuid

                await this.$req
                    .put('locauxequipmwf/' + this.formation.locauxEquipMwfUuid, this.questions)
                    .then(() => {
                        this.$req
                            .get('locauxequipmwf/' + this.formation.locauxEquipMwfUuid)
                            .then(response => {
                                this.formation.locauxEquipMwf = response
                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            }else {
                await this.$req
                    .post('locauxequipmwf', this.questions)
                    .then(response => {
                        this.formation.locauxEquipMwfUuid = response.uuid
                        this.formation.locauxEquipMwf = response

                        this.$req
                            .put('formations/' + this.formation.uuid, this.formation)
                            .then(() => {
                                this.$toast.default('Enregistrement réussi !');
                            })
                    })
            }
        }
    },
    mounted: async function() {
        if(this.formation.locauxEquipMwfUuid != null) {
            await this.$req
                .get('locauxequipmwf/' + this.formation.locauxEquipMwfUuid)
                .then(response => {
                    this.questions = response
                    this.loaded = true;
                })
                .catch(() => {
                    this.loaded = true
                    this.loadError = true
                })
        }else{
            this.loaded = true
        }
    }
}
</script>