<template>
    <div>
        <div
            v-if="listFormations == null && !loadError"
            class="text-center pa-5">
            <v-progress-circular
                indeterminate
                color="primary"/>
        </div>
        <div
            v-else-if="listFormations == null && loadError"
            class="text-center pa-5">
            <v-alert
                type="warning"
                class="d-inline-block">
                Une erreur est survenu durant le chargement des données
            </v-alert>
        </div>
        <v-card
            v-else
            class="overflow-hidden elevation-0">
            <v-toolbar
                flat
                color="secondary"
                dark>
                <v-icon class="mr-2">mdi-cog</v-icon>
                <v-toolbar-title class="font-weight-light">
                    Formation
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-combobox
                    :value="local.priorityLevel"
                    @input="update('priorityLevel', $event)"
                    :items="priorityLevels"
                    label="Degré d'urgence"
                    item-text="label"
                    item-value="level"/>

                <v-dialog
                    ref="dialog"
                    v-model="modalDate"
                    :return-value.sync="desiredDateLoc"
                    persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="desiredDateLoc"
                            label="Date souhaité"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="desiredDateLoc"
                        color="primary"
                        first-day-of-week="1"
                        type="month"
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalDate = false">
                            Annuler
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(desiredDateLoc)">
                            Valider
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash'

export default {
    name: "SelectFormation",
    props:{
        value: {
            type: Object,
        },
    },
    computed: {
        local () {
            return this.value ?
                this.value :
                {
                    priorityLevel: { level: 0, label: 'Faible' },
                    desiredDate: this.defaultDate(),
                    formationSeen: null,
                    formationProposed: null,
                }
        },
    },
    watch: {
        desiredDateLoc: function(newValue){
            this.update('desiredDate', newValue)
        }
    },
    data: function(){
        return {
            loadError: false,

            priorityLevels: [
                { level: 0, label: 'Faible' },
                { level: 1, label: 'Moyen' },
                { level: 2, label: 'Élevé' },
                { level: 3, label: 'Urgent' },
            ],

            desiredDateLoc: this.defaultDate(),
            listFormations: null,

            modalDate: false,
        }
    },
    mounted () {
        this.initialize()
    },
    methods: {
        initialize () {
            this.$req
                .get('listformations')
                .then(response => this.listFormations = response)
                .catch(() => this.loadError = true)

            this.$emit('input', this.local)
        },
        update (key, value) {
            this.$emit('input', tap(cloneDeep(this.local), v => set(v, key, value)))
        },
        defaultDate() {
            return new Date().toISOString().substr(0, 7)
        },
    },
}
</script>