<template>
    <div>
        <v-col>
            <div v-if="feedbacks == null">
                <v-progress-circular
                    color="primary"
                    indeterminate/>
            </div>
            <div
                v-else-if="!feedbacks.feedbackGenerated"
                class="text-center">
                <v-alert
                    v-if="!formation.validated"
                    type="warning"
                    color="accent">
                    La formation doit être validé avant de générer les feedbacks
                </v-alert>
                <v-btn
                    color="primary"
                    :disabled="!formation.validated || formation.closed"
                    @click="generateFeedbacks"
                    :loading="generating">
                    Générer les feedbacks
                </v-btn>
            </div>
            <div v-else>
                <v-card>
                    <v-card-subtitle>
                        Après la formation
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row class="justify-center">
                            <links-dialog v-model="linksDialog" :links="links"/>
                            <v-btn
                                :disabled="formation.closed"
                                @click="showLinks">
                                Liens des Feedbacks
                            </v-btn>
                        </v-row>
                        <v-card-subtitle>Feedbacks participants</v-card-subtitle>
                        <v-row
                            class="ma-2"
                            v-for="feedback in feedbacks.feedbackCandidates"
                            :key="feedback.uuid">
                            <feedback-row :feedback="feedback"
                                          :disabled="formation.closed"
                                          :name="feedback.title"
                                          type="candidate"
                                          internal-url="FormationDetail.Feedback.Candidate"
                                          external-url="Feedback.Candidate"/>
                        </v-row>
                        <v-row class="justify-center">
                            <qr-code-dialog v-model="qrcodeDialog" :links="qrcodeCandidates"/>
                            <v-btn
                                :disabled="formation.closed"
                                @click="showQrCodes">
                                Voir les QR Codes
                            </v-btn>
                        </v-row>
                        <v-list class="mt-10">
                            <v-list-item>
                                <v-list-item-content>
                                    <feedback-row :feedback="feedbacks.feedbackInstructor"
                                                  :disabled="formation.closed"
                                                  name="Feedback formateur"
                                                  type="instructor"
                                                  internal-url="FormationDetail.Feedback.Instructor"
                                                  external-url="Feedback.Instructor"/>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <feedback-row :feedback="feedbacks.feedbackCompany"
                                                  :disabled="formation.closed"
                                                  name="Feedback entreprise"
                                                  type="company"
                                                  internal-url="FormationDetail.Feedback.Company"
                                                  external-url="Feedback.Company"/>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <feedback-row :feedback="feedbacks.feedbackFinanceur"
                                                  :disabled="formation.closed"
                                                  name="Feedback financeur"
                                                  type="financeur"
                                                  internal-url="FormationDetail.Feedback.Financeur"
                                                  external-url="Feedback.Financeur"/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
    </div>
</template>

<script>
import QrCodeDialog from "@/components/feedback/QrCodeDialog";
import LinksDialog from "@/components/feedback/LinksDialog";
import FeedbackRow from "@/components/formation-details/FeedbackRow";

export default {
    name: "AfterFormation",
    components: {FeedbackRow, LinksDialog, QrCodeDialog},
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        generating: false,
        feedbacks: null,
        qrcodeDialog: false,
        linksDialog: false,

        qrcodeCandidates: [],
        links: [],
    }),
    mounted() {
        this._loadFeedbacks()
    },
    methods: {
        generateFeedbacks() {
            this.generating = true
            this.$req.get('formations/' + this.formation.uuid + '/feedbacks/generate')
                .then(() => {
                    this.$toast.default('Feedbacks générés')
                    this._loadFeedbacks()
                })
        },
        showQrCodes() {
            this.qrcodeDialog = true
        },
        showLinks(){
            this.linksDialog = true
        },
        _loadFeedbacks() {
            this.$req
                .get('formations/' + this.formation.uuid + '/feedbacks')
                .then(data => {
                    this.feedbacks = data
                    this.qrcodeCandidates = []
                    for (const i of this.feedbacks.feedbackCandidates) {
                        this.qrcodeCandidates.push({
                            title: i.title,
                            href: this._toUrl('Feedback.Candidate', i.urlKey)
                        })
                        this.links.push({
                            type: 'Candidat',
                            title: i.title,
                            href: this._toUrl('Feedback.Candidate', i.urlKey)
                        })
                    }

                    this.links.push({
                        type: 'Instructeur',
                        href: this._toUrl('Feedback.Instructor', this.feedbacks.feedbackInstructor.urlKey)
                    })
                    this.links.push({
                        type: 'Entreprise',
                        href: this._toUrl('Feedback.Company', this.feedbacks.feedbackCompany.urlKey)
                    })
                    this.links.push({
                        type: 'Financeur',
                        href: this._toUrl('Feedback.Financeur', this.feedbacks.feedbackFinanceur.urlKey)
                    })
                })
        },
        _toUrl(name, key) {
            return location.host + '/' + this.$router.resolve({name: name, params: {key: key}}).href
        },
    }
}
</script>