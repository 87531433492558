<template>
    <div>
        <div
            v-if="(listCompanies == null) && !loadError"
            class="text-center pa-5">
            <v-progress-circular
                indeterminate
                color="primary"/>
        </div>
        <div
            v-else-if="(listCompanies == null) && loadError"
            class="text-center pa-5">
            <v-alert
                type="warning"
                class="d-inline-block">
                Une erreur est survenu durant le chargement des données
            </v-alert>
        </div>
        <v-card
            v-else
            class="overflow-hidden elevation-0">
            <v-toolbar
                flat
                color="secondary"
                dark>
                <v-icon class="mr-2">mdi-home-city</v-icon>
                <v-toolbar-title class="font-weight-light">
                    Entreprise
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialogCreate"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            light
                            v-bind="attrs"
                            v-on="on">
                            Ajouter une entreprise
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                Ajout d'une entreprise
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-form
                                    ref="form"
                                    v-model="formValid">
                                    <TmplCompanies :edited-item="editedItem"/>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="close">
                                Annuler
                            </v-btn>
                            <v-btn
                                :disabled="!formValid"
                                color="primary"
                                text
                                @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-card-text>
                <v-autocomplete
                    v-model="internalSelectedCompanies"
                    :items="listCompanies"
                    item-text="name"
                    item-value="uuid"
                    label="Nom"
                    auto-select-first/>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import TmplCompanies from "@/components/crud/TmplCompanies";

export default {
    name: "SelectCompany",
    components: {TmplCompanies},
    props:{
        value: {
            type: Object
        }
    },
    data: function(){
        return {
            loadError: false,

            internalSelectedCompanies: null,
            listCompanies: null,

            dialogCreate: false,

            formValid: true,

            editedUuid: null,
            editedItem: {
                name: '',
                contactEmail: '',
                address: {
                    addr1: '',
                    addr2: '',
                    zip: 0,
                    city: '',
                    country: ''
                }
            },
            defaultItem: {
                name: '',
                contactEmail: '',
                address: {
                    addr1: '',
                    addr2: '',
                    zip: 0,
                    city: '',
                    country: ''
                }
            },
        }
    },
    created () {
        if(this.value != null) {
            this.internalSelectedCompanies = this.value.uuid
        }
    },
    watch: {
        internalSelectedCompanies (val) {
            this.selectedCompanies = val
        }
    },
    computed: {
        selectedCompanies: {
            get() {
                return this.internalSelectedCompanies
            },
            set(val) {
                for(let i = 0; i < this.listCompanies.length; i++) {
                    if (this.listCompanies[i].uuid === val) {
                        this.internalSelectedCompanies = this.listCompanies[i].uuid
                        this.$emit('input', this.listCompanies[i])
                    }
                }
            }
        }
    },
    methods: {
        close () {
            this.dialogCreate = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedUuid = null
            })
        },
        save () {
            this.$refs.form.validate()

            this.$req
                .post('companies', this.editedItem)
                .then(response => {
                    this.listCompanies.push(response)
                    this.internalSelectedCompanies = response.uuid
                    this.close();
                })
        },
    },
    mounted: function() {
        this.$req
            .get('companies')
            .then(response => {
                this.listCompanies = response
            })
            .catch(() => this.loadError = true)
    }
}
</script>