<template>
    <div>
        <v-container>
            <div class="mb-5 text-right">
                <router-link to="/formation/create">
                    <v-btn
                        color="primary">
                        Nouvelle formation
                    </v-btn>
                </router-link>
            </div>

            <v-tabs v-model="tab" class="d-inline-block">
                <v-tab>Formation en cours / à venir</v-tab>
                <v-tab>Formation clôturé</v-tab>
                <v-tab>Formation annulés</v-tab>
            </v-tabs>
            <v-row align="center" class="ma-0">
                <span class="mr-3">Trier par:</span>
                <v-radio-group row v-model="filter" @change="changeFilter">
                    <v-radio label="Priorité" class="mr-6" value="PRIORITY"/>
                    <v-radio label="Date de formation" class="mr-6" value="DATE"/>
                    <v-radio label="État" class="mr-6" value="STATE"/>
                </v-radio-group>

                <span class="mx-3">Ordre:</span>
                <v-radio-group row v-model="filterOrder" @change="changeFilter">
                    <v-radio label="Ascendant" class="mr-6" value="ASC"/>
                    <v-radio label="Descendant" class="mr-6" value="DESC"/>
                </v-radio-group>
            </v-row>
            <v-tabs-items v-model="tab" class="py-1 px-1">
                <v-tab-item>
                    <FormationsGroup class="mt-3" :formations="formations" :load-error="loadError"/>
                </v-tab-item>
                <v-tab-item>
                    <FormationsGroup class="mt-3" :formations="formationsClosed" :load-error="loadError"/>
                </v-tab-item>
                <v-tab-item>
                    <FormationsGroup class="mt-3" :formations="formationsCancelled" :load-error="loadError"/>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>

<script>
import FormationsGroup from "@/views/formations/list/FormationsGroup";
import * as _ from "lodash";

export default {
    name: "Formations",
    components: {FormationsGroup},
    data: () => ({
        tab: 0,
        loadError: false,
        filter: 'PRIORITY',
        filterOrder: 'ASC',

        formations: null,
        formationsClosed: null,
        formationsCancelled: null,
    }),
    methods: {
        changeFilter(){
            let order = this.filterOrder === 'DESC' ? -1 : 1
            let orderInv = order === 1 ? -1 : 1

            switch(this.filter){
            case "DATE":
                this.formations = this.formations.sort((a,b) => (this._date(a) < this._date(b)) ? order : orderInv)
                this.formationsClosed = this.formationsClosed.sort((a,b) => (this._date(a) < this._date(b)) ? order : orderInv)
                this.formationsCancelled = this.formationsCancelled.sort((a,b) => (this._date(a) < this._date(b)) ? order : orderInv)
                break;
            case "STATE":
                this.formations = this.formations.sort((a,b) => (this._status(a) > this._status(b)) ? order : orderInv)
                this.formationsClosed = this.formationsClosed.sort((a,b) => (this._status(a) > this._status(b)) ? order : orderInv)
                this.formationsCancelled = this.formationsCancelled.sort((a,b) => (this._status(a) > this._status(b)) ? order : orderInv)
                break;
            default:
                this.formations = this.formations.sort((a,b) => (a.priorityLevel < b.priorityLevel) ? order : orderInv)
                this.formationsClosed = this.formationsClosed.sort((a,b) => (a.priorityLevel < b.priorityLevel) ? order : orderInv)
                this.formationsCancelled = this.formationsCancelled.sort((a,b) => (a.priorityLevel < b.priorityLevel) ? order : orderInv)
            }
        },
        _status(formation){
            if (formation.closed) {
                return 0;
            } else if (formation.quoteAccepted) {
                return 1;
            } else if (formation.quoteSended) {
                return 2;
            } else {
                return 3;
            }
        },
        _date (formation) {
            let mindate = null
            for (const dates of formation.formationsChosen.map(e => e.dates)) {
                let min = _.min(dates.map(e => e.selectedDate))

                if (mindate == null || min < mindate)
                    mindate = min
            }

            if (mindate == null) {
                if(formation.desiredDate != null){
                    return new Date(formation.desiredDate)
                }

                return null
            }

            return new Date(mindate)
        },
    },
    mounted() {
        this.$req
            .get('formations')
            .then((data) => {
                this.formations = data.filter(e => !e.closed && !e.cancelled)
                this.formationsClosed = data.filter(e => e.closed && !e.cancelled)
                this.formationsCancelled = data.filter(e => e.cancelled)

                this.changeFilter()
            })
            .catch(() => this.loadError = true)
    }
}
</script>