<template>
    <v-col>
        <v-textarea
            v-model="note"
            :disabled="formation.closed"
            outlined
            rows="10"
            label="Notes concernant la formation"/>

        <div class="text-right">
            <v-btn
                color="primary"
                :disabled="formation.closed"
                @click="save">
                Enregistrer
            </v-btn>
        </div>
    </v-col>
</template>

<script>
export default {
    name: "Notes",
    props: {
        formation: {
            type: Object
        }
    },
    mounted () {
        this.note = this.formation.note
    },
    data: () => ({
        note: '',
    }),
    methods: {
        save () {
            this.formation.note = this.note
            this.$req
                .put('formations/' + this.formation.uuid, this.formation)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                })
        }
    }
}
</script>