<template>
    <v-col>
        <div
            class="text-center"
            v-if="listInstructors == null || listFormations == null">
            <v-progress-circular
                color="primary"
                indeterminate/>
        </div>
        <div v-else>
            <div v-if="!formation.closed && formation.quoteAccepted">
                <v-alert
                    class="text-center"
                    border="top"
                    colored-border
                    color="secondary"
                    dense
                    elevation="2">
                    La confirmation a été envoyé, il n'est par conséquent plus possible d'éditer ces informations
                </v-alert>
                <v-alert
                    v-if="$auth.hasManagerRights && !forceEdition"
                    class="text-center"
                    type="info"
                    color="accent"
                    border="bottom"
                    colored-border
                    elevation="2">
                    Accès manager:
                    <v-btn light @click="forceEdition = true">
                        Éditer le document
                    </v-btn>
                </v-alert>
            </div>
            <div>
                <v-text-field
                    v-model="formation.name"
                    :disabled="formation.quoteAccepted && !forceEdition"
                    label="Nom de la formation"/>

                <v-autocomplete
                    v-model="selectedInstructor"
                    :disabled="formation.quoteAccepted && !forceEdition"
                    :items="listInstructors"
                    item-value="uuid"
                    label="Formateur"
                    clearable>
                    <template v-slot:selection="data">
                        {{ data.item.lastname }} {{ data.item.firstname }}
                    </template>
                    <template v-slot:item="data">
                        {{ data.item.lastname }} {{ data.item.firstname }}
                    </template>
                </v-autocomplete>

                <v-text-field
                    v-model="formationCost"
                    :disabled="formation.quoteAccepted && !forceEdition"
                    label="Coût de la formation (€, hors taxes)"
                    type="number"/>

                <v-card
                    :disabled="formation.quoteAccepted && !forceEdition">
                    <v-toolbar
                        flat
                        color="primary"
                        dark>
                        <v-toolbar-title>Formations</v-toolbar-title>
                        <v-spacer/>
                        <v-dialog
                            v-model="dialogCreate"
                            max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    light
                                    color="accent"
                                    v-bind="attrs"
                                    v-on="on">
                                    Ajouter une formation
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">
                                        Ajout d'une formation
                                    </span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-autocomplete
                                            v-model="editedItem.formationUuid"
                                            label="Formations proposés"
                                            :items="listFormations"
                                            item-text="name"
                                            item-value="uuid"/>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="close">
                                        Annuler
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="addNew">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>

                    <v-card-text
                        v-if="selectedFormationsDate == null || selectedFormationsDate.length === 0">
                        Aucune formation n'a été ajouté.
                    </v-card-text>
                    <v-card-text
                        v-else
                        v-for="(selected, index) in selectedFormationsDate"
                        :key="index">
                        <v-row>
                            <v-col
                                cols="7">
                                <div>Formation:
                                    <v-chip color="accent">{{ selected.formation.name }}</v-chip>
                                </div>
                            </v-col>
                            <v-col
                                cols="5">
                                <div class="text-right">
                                    <v-dialog
                                        ref="dialog"
                                        v-model="selected.modal"
                                        :return-value.sync="selected"
                                        :retain-focus="false"
                                        persistent
                                        width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                class="mb-3"
                                                small
                                                v-on="on">
                                                Sélectionner les dates
                                            </v-btn>
                                        </template>
                                        <v-date-picker
                                            v-model="selected.dates"
                                            first-day-of-week="1"
                                            multiple
                                            scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="selected.modal = false">
                                                Annuler
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dialog[index].save(selected.dates)">
                                                Valider
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>

                                    <v-btn
                                        class="ml-3 mb-3"
                                        small
                                        @click="remove(selected)">
                                        Supprimer
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Dates:
                                <v-chip-group>
                                    <v-chip
                                        small
                                        v-for="date in selected.dates"
                                        :key="date">
                                        {{ date }}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                        <v-divider/>
                    </v-card-text>
                </v-card>

                <v-btn
                    class="mt-6"
                    color="primary"
                    @click="save"
                    :disabled="formation.quoteAccepted && !forceEdition">
                    Enregistrer
                </v-btn>
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    name: "Internal",
    props: {
        formation: {
            type: Object,
        }
    },
    created() {
        this.selectedInstructor = this.formation.instructorUuid
        this.formationCost = this.formation.cost
    },
    data: () => ({
        forceEdition: false,
        formationCost: 0,
        selectedInstructor: null,
        selectedFormationsDate: [],
        indexCustomId: 0,

        editedUuid: null,
        editedItem: {
            formationUuid: '',
        },
        defaultItem: {
            formationUuid: '',
        },

        dialogCreate: false,

        listInstructors: null,
        listFormations: null,
    }),
    mounted() {
        this.$req
            .get('instructors')
            .then(response => {
                this.listInstructors = response
            })

        this.$req
            .get('listformations')
            .then(response => {
                this.listFormations = response
            })

        this.$req
            .get('formations/' + this.formation.uuid + '/formationsChosen')
            .then(response => {
                response.forEach(item => {
                    this.selectedFormationsDate.push({
                        uuid: item.uuid,
                        modal: false,
                        customId: item.uuid,
                        formation: item.listFormation,
                        dates: item.dates.map(i => i.selectedDate.substr(0, 10))
                    });
                })
            })
    },
    methods: {
        updateFormationChosen: async function () {
            await this.$req
                .get('formations/' + this.formation.uuid + '/formationsChosen')
                .then(response => {
                    response.forEach(item => {
                        if (!this.selectedFormationsDate.map(i => i.uuid).includes(item.uuid)) {
                            this.$req.del('formations/' + this.formation.uuid + '/formationsChosen/' + item.uuid)
                        }
                    })
                    this.selectedFormationsDate.forEach(item => {
                        if (response.map(i => i.uuid).includes(item.uuid)) {
                            //PUT
                            this.$req
                                .put('formations/' + this.formation.uuid + '/formationsChosen/' + item.uuid, {
                                    uuid: item.uuid,
                                    formationUuid: this.formation.uuid,
                                    listformationUuid: item.formation.uuid,
                                    dates: item.dates
                                })
                        } else {
                            //POST
                            this.$req
                                .post('formations/' + this.formation.uuid + '/formationsChosen', {
                                    formationUuid: this.formation.uuid,
                                    listformationUuid: item.formation.uuid,
                                    dates: item.dates
                                })
                        }
                    })
                })
                .catch(error => this.$modal.showError(error))
        },
        save: async function () {
            this.formation.instructorUuid = this.selectedInstructor
            this.formation.cost = this.formationCost
            this.$req
                .put('formations/' + this.formation.uuid, this.formation)
                .then(() => {
                    this.updateFormationChosen()
                    this.$toast.default('Modification enregistré')
                })
        },

        addNew() {
            if(this.selectedFormationsDate.filter(f => f.formation.uuid === this.editedItem.formationUuid).length > 0){
                // La formation a déjà été ajouté
                this.$toast.info('Cette formation est déjà dans la liste. Il est possible de mettre différentes dates non continu pour une formation.')
                return
            }

            let formation = this.listFormations.filter(f => f.uuid === this.editedItem.formationUuid)

            if (formation.length > 0) {
                this.selectedFormationsDate.push({
                    uuid: null,
                    modal: false,
                    customId: this.indexCustomId,
                    formation: formation[0],
                    dates: [],
                })
                this.indexCustomId++
            }

            this.close()
        },
        remove(element) {
            this.selectedFormationsDate = this.selectedFormationsDate.filter(i => i.customId !== element.customId)
        },
        close() {
            this.dialogCreate = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedUuid = null
            })
        }
    },
}
</script>