<template>
    <v-row>
        <v-col cols="9" align-self="center">
            <v-checkbox
                v-model="feedback.validated"
                readonly>
                <template v-slot:label>
                    <router-link
                        :to="{name: internalUrl, params: { key: feedback.urlKey }}">
                        {{ name }}
                    </router-link>
                </template>
            </v-checkbox>
        </v-col>
        <v-col class="text-center" align-self="center">
            <v-btn
                :disabled="disabled"
                @click="toggleValidation(type, feedback)">
                <v-icon>{{ feedback.validated ? 'mdi-lock-open' : 'mdi-lock' }}</v-icon>
            </v-btn>
        </v-col>
        <v-col class="text-center" align-self="center">
            <v-btn
                :disabled="disabled"
                :to="{name: externalUrl, params: { key: feedback.urlKey }}"
                target="_blank">
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "FeedbackRow",
    props: ['disabled', 'name', 'feedback', 'type', 'internalUrl', 'externalUrl'],
    methods: {
        toggleValidation(type, feedback) {
            if(feedback.validated){
                this.reopenFeedback(type, feedback)
            }else{
                this.cancelFeedback(type, feedback)
            }
        },
        reopenFeedback(type, feedback) {
            this.$req
                .put('feedbacks/' + type + '/' + feedback.uuid + '/reopen')
                .then(() => {
                    this.$toast.default('Feedback réouvert')
                    feedback.validated = false
                })
        },
        cancelFeedback(type, feedback) {
            this.$req
                .put('feedbacks/' + type + '/' + feedback.uuid + '/cancel')
                .then(() => {
                    this.$toast.default('Feedback quittancé')
                    feedback.validated = true
                })
        },
    },
}
</script>

<style scoped>

</style>