<template>
    <v-data-table
        :items="crudObjects"
        :headers="crudHeaders">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    v-model="dialog"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            :dark="!disabled"
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disabled">
                            {{ newTitle }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form
                                    ref="form"
                                    v-model="formValid">
                                    <slot :editedItem="editedItem"></slot>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="close">
                                Annuler
                            </v-btn>
                            <v-btn
                                :disabled="!formValid"
                                color="primary"
                                text
                                @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">Veuillez confirmer la suppression.</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDelete">Annuler</v-btn>
                            <v-btn color="primary" text @click="deleteItemConfirm">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                :disabled="disabled"
                @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon
                small
                :disabled="disabled"
                @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <div v-if="loading">
                <v-progress-circular
                    indeterminate/>
            </div>
            <div v-else>
                Aucune donnée disponible
            </div>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "Crud",
    props:{
        title: {
            type: String
        },
        newTitle: {
            type: String
        },
        crudHeaders: {
            type: Array
        },
        defaultItem: {
            type: Object
        },
        uriRest:{
            type: String
        },
        disabled: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        loading: true,

        dialog: false,
        dialogDelete: false,
        crudObjects: [],

        formValid: true,
        editedUuid: null,
        editedItem: null,
    }),
    computed: {
        formTitle () {
            return this.editedUuid === null ? 'Ajouter' : 'Éditer'
        },
    },
    watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
    },
    created () {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.initialize()
    },
    methods: {
        initialize () {
            this.$req
                .get(this.uriRest)
                .then(response => {
                    this.crudObjects = response
                    this.loading = false
                })
                .catch(() => this.loading = false)
        },

        editItem (item) {
            this.editedUuid = item.uuid
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedUuid = item.uuid
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            let editedUuid = this.editedUuid
            this.$req
                .del(this.uriRest + '/' + editedUuid)
                .then(() => {
                    this.crudObjects = this.crudObjects.filter(i => i.uuid !== editedUuid)
                })
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedUuid = null
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedUuid = null
            })
        },

        save () {
            this.$refs.form.validate()

            if(this.formValid) {
                if (this.editedUuid !== null) {
                    this.$req
                        .put(this.uriRest + '/' + this.editedUuid, this.editedItem)
                        .then(response => {
                            for (let i = 0; i < this.crudObjects.length; i++) {
                                if (this.crudObjects[i].uuid === response.uuid) {
                                    Object.assign(this.crudObjects[i], response)
                                    break
                                }
                            }
                        }).catch(error => {
                            this.$modal.showError(error)
                        })
                } else {
                    this.$req
                        .post(this.uriRest, this.editedItem)
                        .then(response => {
                            this.crudObjects.push(response)
                        })
                }
                this.close()
            }
        },
    },
}
</script>