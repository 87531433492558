<template>
    <v-col>
        <div v-if="feedbacks == null" class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate/>
        </div>
        <div v-else-if="formation.checklist == null">
            <v-alert color="primary" type="warning">Internal error</v-alert>
        </div>
        <div v-else>
            <v-expansion-panels
                :value="openPanels"
                multiple
                focusable>
                <ChecklistGroup
                    v-for="group in checklistGroups"
                    :key="group.title"
                    :title="group.title"
                    :validation="group.validation"
                    :validated="group.validated"
                    :checkboxes="group.checkboxes"
                    :disabled="formation.closed"
                    @check="emit"/>
            </v-expansion-panels>
        </div>
    </v-col>
</template>

<script>
import ChecklistGroup from "@/components/formation-details/ChecklistGroup";

export default {
    name: "Checklist",
    components: {ChecklistGroup},
    props: {
        formation: {
            type: Object
        }
    },
    computed: {
        openPanels(){
            let output = [];
            for(let i = 0; i < this.checklistGroups.length; i++){
                if(!this.checklistGroups[i].checkboxes.every(cb => cb.value))
                    output.push(i)
            }

            return output;
        },
        checklistGroups() {
            return [
                {
                    title: 'Définition du besoin client',
                    checkboxes: [
                        {
                            title: 'Fiche d\'entretien initial',
                            value: this.formation.initialChips != null,
                        }
                    ],
                },
                {
                    title: 'Préparation du devis',
                    checkboxes: [
                        {
                            title: '0mwfrance entete new (FORMATIONS) > layout dans l\'ERP',
                            value: this.formation.quoteSended,
                        },
                        {
                            title: 'Modalité administratives et pédagogiques',
                            value: this.formation.quoteSended,
                        },
                        {
                            title: 'Programmes de formations',
                            value: this.formation.quoteSended,
                        },
                        {
                            title: 'Conditions générales de ventes',
                            value: this.formation.quoteSended,
                        },
                        {
                            title: 'Règlement intérieur',
                            value: this.formation.quoteSended,
                        },
                        {
                            title: 'Convention de formation',
                            value: this.formation.quoteSended,
                        },
                    ],
                },
                {
                    title: 'Préparation du dossier (envoie de la confirmation)',
                    checkboxes: [
                        {
                            title: '01mwfrance en-tête > layout dans l\'ERP',
                            value: this.formation.confirmationSended,
                        },
                        {
                            title: 'Confirmation formation au client',
                            value: this.formation.confirmationSended,
                        },
                        {
                            title: 'Questionnaire relatif aux locaux et équipments',
                            value: this.formation.confirmationSended,
                        },
                        {
                            title: 'Livret d\'accueil avec aspects pratiques',
                            value: this.formation.confirmationSended,
                        },
                        {
                            title: 'Programmes de formations',
                            value: this.formation.confirmationSended,
                        },
                    ],
                },
                {
                    title: 'Préparation du dossier (avant la formation)',
                    checkboxes: [
                        {
                            title: 'Feuille d\'émargement',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_FeuilleEmargement',
                            value: this.formation.checklist.beforeFormation_FeuilleEmargement != null,
                            validatedBy: this.formation.checklist.beforeFormation_FeuilleEmargement,
                        },
                        {
                            title: 'Positionnement et évaluation à l\'entrée',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_PositionnementEvaluation',
                            value: this.formation.checklist.beforeFormation_PositionnementEvaluation != null,
                            validatedBy: this.formation.checklist.beforeFormation_PositionnementEvaluation,
                        },
                        {
                            title: 'Objectifs et évaluations des formations',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_ObjectifsEvaluation',
                            value: this.formation.checklist.beforeFormation_ObjectifsEvaluation != null,
                            validatedBy: this.formation.checklist.beforeFormation_ObjectifsEvaluation,
                        },
                        {
                            title: 'Fiche d\'évaluation à chaud stagiaires',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_FicheEvalStagiaires',
                            value: this.formation.checklist.beforeFormation_FicheEvalStagiaires != null,
                            validatedBy: this.formation.checklist.beforeFormation_FicheEvalStagiaires,
                        },
                        {
                            title: 'Fiche d\'évaluation aux financeurs',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_FicheEvalFinanceurs',
                            value: this.formation.checklist.beforeFormation_FicheEvalFinanceurs != null,
                            validatedBy: this.formation.checklist.beforeFormation_FicheEvalFinanceurs,
                        },
                        {
                            title: 'Fiche d\'évaluation à chaud de l\'entreprise',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_FicheEvalEntreprise',
                            value: this.formation.checklist.beforeFormation_FicheEvalEntreprise != null,
                            validatedBy: this.formation.checklist.beforeFormation_FicheEvalEntreprise,
                        },
                        {
                            title: 'Fiche d\'évaluation à chaud formateur',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_FicheEvalFormateur',
                            value: this.formation.checklist.beforeFormation_FicheEvalFormateur != null,
                            validatedBy: this.formation.checklist.beforeFormation_FicheEvalFormateur,
                        },
                        {
                            title: 'Aide mémoire évaluations des formations',
                            action: 'à imprimer',
                            checklistAttr: 'beforeFormation_AideMemoire',
                            value: this.formation.checklist.beforeFormation_AideMemoire != null,
                            validatedBy: this.formation.checklist.beforeFormation_AideMemoire,
                        },
                    ],
                },
                {
                    title: 'Formation (au début de la formation)',
                    checkboxes: [
                        {
                            title: 'Questionnaire relatif aux locaux et équipement',
                            action: 'à compléter',
                            value: this.formation.locauxEquipMwf != null,
                        },
                        {
                            title: 'Séquence de la formation',
                            action: 'présenter oralement',
                            checklistAttr: 'startFormation_Sequence',
                            value: this.formation.checklist.startFormation_Sequence != null,
                            validatedBy: this.formation.checklist.startFormation_Sequence,
                        },
                        {
                            title: 'Positionnement et évaluation à l\'entrée',
                            action: 'prise de note',
                            checklistAttr: 'startFormation_PositionnementEvaluation',
                            value: this.formation.checklist.startFormation_PositionnementEvaluation != null,
                            validatedBy: this.formation.checklist.startFormation_PositionnementEvaluation,
                        },
                        {
                            title: 'Feuille d\'émargement',
                            action: 'à faire signer',
                            checklistAttr: 'startFormation_FeuilleEmargement',
                            value: this.formation.checklist.startFormation_FeuilleEmargement != null,
                            validatedBy: this.formation.checklist.startFormation_FeuilleEmargement,
                        },
                        {
                            title: 'Support de cours MW',
                            action: 'à distribuer',
                            checklistAttr: 'startFormation_SupportCours',
                            value: this.formation.checklist.startFormation_SupportCours != null,
                            validatedBy: this.formation.checklist.startFormation_SupportCours,
                        },
                    ],
                },
                {
                    title: 'Formation (au terme de la formation)',
                    checkboxes: [
                        {
                            title: 'Objectifs et évaluation des formations',
                            checklistAttr: 'endFormation_ObjectifsEvaluation',
                            value: this.formation.checklist.endFormation_ObjectifsEvaluation != null,
                            validatedBy: this.formation.checklist.endFormation_ObjectifsEvaluation,
                        },
                        {
                            title: 'Fiche d\'évaluation à chaud stagiaires',
                            value: this.feedbacks != null && this.feedbacks.feedbackCandidates != null
                            && this.feedbacks.feedbackCandidates.length > 0 ?
                                this.feedbacks.feedbackCandidates.every(e => {
                                    return e.validated
                                }) : false,
                        },
                        {
                            title: 'Fiche d\'évaluation aux financeurs',
                            value: this.feedbacks != null && this.feedbacks.feedbackFinanceur != null ?
                                this.feedbacks.feedbackFinanceur.validated : false,
                        },
                        {
                            title: 'Fiche d\'évaluation de l\'entreprise',
                            value: this.feedbacks != null && this.feedbacks.feedbackCompany != null ?
                                this.feedbacks.feedbackCompany.validated : false,
                        },
                        {
                            title: 'Fiche d\'évaluation à chaud du formateur',
                            value: this.feedbacks != null && this.feedbacks.feedbackInstructor != null ?
                                this.feedbacks.feedbackInstructor.validated : false,
                        }
                    ],
                },
                {
                    title: 'Formation (après la formation)',
                    checkboxes: [
                        {
                            title: 'Fiche de synthèse post formation',
                            value: this.formation.synthese != null,
                        },
                        {
                            title: 'Certificat de formation',
                            checklistAttr: 'afterFormation_Certificate',
                            value: this.formation.checklist.afterFormation_Certificate != null,
                            validatedBy: this.formation.checklist.afterFormation_Certificate,
                        },
                        {
                            title: 'Suivi des formations',
                            checklistAttr: 'afterFormation_LivretGestion',
                            value: this.formation.checklist.afterFormation_LivretGestion != null,
                            validatedBy: this.formation.checklist.afterFormation_LivretGestion,
                        },
                        {
                            title: 'Clôture de la formation',
                            value: this.formation.closed,
                        }
                    ],
                },
            ]
        }
    },
    data: function () {
        return {
            feedbacks: null,
        }
    },
    methods: {
        emit(attr, value) {
            this.formation.checklist[attr] = value;
            this.$req
                .put('checklist/' + this.formation.checklistUuid, this.formation.checklist)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                })
                .catch(() => {
                    this.$toast.error('Une erreur est survenu')
                })
        }
    },
    mounted() {
        this.$req
            .get('formations/' + this.formation.uuid + '/feedbacks')
            .then(data => {
                this.feedbacks = data
            })
    }
}
</script>