<template>
    <v-dialog v-model="value" max-width="80%" @input="close">
        <v-card>
            <v-card-title class="headline">QR Codes</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        v-for="(link, index) in links"
                        :key="index">
                        <v-card>
                            <div class="text-center pa-2">
                                <qrcode-vue :value="link.href" size="200"/>
                            </div>
                            <v-divider/>
                            <v-card-title class="font-weight-bold">{{ link.title }}</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Fermer</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: "QrCodeDialog",
    components: {
        QrcodeVue
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Array
        }
    },
    methods: {
        close () {
            this.$emit('input', false)
        }
    }
}
</script>