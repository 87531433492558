<template>
    <div
        v-if="formation.cancelled"
        class="mb-10">
        <v-alert
            class="text-center"
            type="info"
            color="secondary"
            border="bottom"
            colored-border
            elevation="2">
            La formation a été annulé.
        </v-alert>
    </div>
    <div
        v-else-if="formation.closed && $auth.hasManagerRights"
        class="mb-10">
        <v-alert
            class="text-center"
            type="info"
            color="accent"
            border="bottom"
            colored-border
            elevation="2">
            Accès manager:
            <v-btn @click="reopenFormation" light>
                Réouvrir la formation
            </v-btn>
        </v-alert>
    </div>
    <div v-else>
        <div
            v-if="feedbacks != null"
            class="text-center mb-5">
            <div>
                <v-btn
                    color="primary"
                    :disabled="formation.closed || formation.cancelled"
                    @click="closeFormation">
                    La formation est terminé, clôturer.
                </v-btn>
            </div>
        </div>

        <v-alert
            v-if="!$auth.hasManagerRights && !formation.closed"
            type="info"
            class="text-center"
            border="top"
            colored-border
            color="secondary"
            dense
            elevation="3">Vous n'avez pas les droits nécessaires pour annuler la formation
        </v-alert>
        <div
            class="text-center">
            <div>
                <v-btn
                    color="primary"
                    :disabled="formation.cancelled || formation.closed || !$auth.hasManagerRights"
                    @click="cancelFormation">
                    La formation est annulé.
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CloseFormation",
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        feedbacks: null,
    }),
    computed: {
        feedbackComplete: {
            get: function () {
                return this.feedbacks != null &&
                    (this.feedbacks.feedbackCandidates != null && this.feedbacks.feedbackCandidates.length > 0 ?
                        this.feedbacks.feedbackCandidates.every(e => {
                            return e.validated
                        }) :
                        false)
                    && (this.feedbacks.feedbackFinanceur != null ?
                        this.feedbacks.feedbackFinanceur.validated :
                        false)
                    && (this.feedbacks.feedbackCompany != null ?
                        this.feedbacks.feedbackCompany.validated :
                        false)
                    && (this.feedbacks.feedbackInstructor != null ?
                        this.feedbacks.feedbackInstructor.validated :
                        false)
            }
        },
        checklistComplete: {
            get: function () {
                return !Object.values(this.formation.checklist).some(x => (x === null || x === '')) &&
                    this.feedbackComplete &&
                    this.formation.quoteSended &&
                    this.formation.quoteAccepted &&
                    this.formation.confirmationSended
            }
        }
    },
    mounted() {
        this._loadFeedbacks()
    },
    methods: {
        closeFormation() {
            if (!this.checklistComplete) {
                this.$modal.show({
                    title: 'Vérification checklist',
                    text: 'Attention, certain point de la checklist n\'ont pas encore été compléter, voulez-vous vraiment fermer cette formation ?',
                    buttons: 'yesno',
                    onConfirmYes: this.confirmClose
                })
            } else {
                this.confirmClose()
            }
        },
        cancelFormation() {
            this.$modal.show({
                title: 'Confirmer l\'annulation de la formation',
                text: 'Attention, l\'annulation d\'une formation est une opération définitive.',
                buttons: 'yesno',
                onConfirmYes: this.confirmCancel
            })
        },
        confirmClose() {
            let formation = this.formation
            formation.closedAt = new Date()

            this.$req
                .put('formations/' + formation.uuid, formation)
                .then(data => {
                    this.formation.closed = data.closed
                    this.formation.closedAt = data.closedAt
                    this.$toast.default('La formation a été clôturé')
                })
        },
        confirmCancel() {
            let formation = this.formation
            formation.cancelledAt = new Date()

            this.$req
                .del('formations/' + formation.uuid)
                .then(() => {
                    this.formation.cancelled = true
                    this.formation.cancelledAt = new Date()
                    this.formation.closed = true
                    this.formation.closedAt = new Date()
                    this.$toast.default('La formation a été annulé')
                })
        },
        reopenFormation() {
            let formation = this.formation
            formation.closedAt = null

            this.$req
                .put('formations/' + formation.uuid, formation)
                .then(data => {
                    this.formation.closed = data.closed
                    this.formation.closedAt = data.closedAt
                    this.$toast.default('La formation a été clôturé')
                })
        },
        _loadFeedbacks() {
            this.$req
                .get('formations/' + this.formation.uuid + '/feedbacks')
                .then(data => {
                    this.feedbacks = data
                    this.qrcodeCandidates = []
                    for (const i of this.feedbacks.feedbackCandidates) {
                        this.qrcodeCandidates.push({
                            title: i.title,
                            href: 'https://formations.mwfrance.fr/#/feedback/candidate/' + i.urlKey
                        })
                    }
                })
        }
    }
}
</script>