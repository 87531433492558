<template>
    <CrudListFormations :disabled="!$auth.hasAdminRights"/>
</template>

<script>
import CrudListFormations from "@/components/crud/CrudListFormations";

export default {
    name: "SettingsFormations",
    components: {CrudListFormations}
}
</script>