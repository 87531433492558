<template>
    <v-card
        shaped>
        <v-list-item three-line>
            <v-list-item-content>
                <v-row class="overline ma-0 mb-4">
                    <v-chip
                        small
                        :color="state.color">
                        {{ state.status }}
                    </v-chip>
                    <v-spacer/>
                    <v-chip
                        small
                        dark
                        :color="priority.color">
                        {{ priority.label }}
                    </v-chip>
                </v-row>
                <v-list-item-title class="headline mb-1">
                    {{ formation.company.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                    v-if="minDate && maxDate && formation.instructor != null">
                    {{ minDate }} - {{ maxDate }} / {{ formation.instructor.fullname }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    v-else-if="formation.desiredDateStr != null && desiredDateStr !== ''">
                    Période désiré: {{ formation.desiredDateStr }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    v-else>
                    Aucune date de formation défini
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-actions>
            <v-spacer/>
            <router-link :to="{ name: 'FormationDetail.Checklist', params: { uuid: formation.uuid } }">
                <v-btn
                    outlined
                    rounded
                    text>
                    Voir
                </v-btn>
            </router-link>
        </v-card-actions>
    </v-card>
</template>

<script>
import * as _ from 'lodash'

export default {
    name: "FormationCard",
    props: {
        formation: {
            type: Object
        },
    },
    computed: {
        minDate: function () {
            let mindate = null
            for (const dates of this.formation.formationsChosen.map(e => e.dates)) {
                let min = _.min(dates.map(e => e.selectedDate))

                if (mindate == null || min < mindate)
                    mindate = min
            }

            if (mindate == null)
                return

            return new Date(mindate).toLocaleDateString()
        },
        maxDate: function () {
            let maxdate = null
            for (const dates of this.formation.formationsChosen.map(e => e.dates)) {
                let max = _.max(dates.map(e => e.selectedDate))

                if (maxdate == null || max > maxdate)
                    maxdate = max
            }

            if (maxdate == null)
                return

            return new Date(maxdate).toLocaleDateString()
        },
    },
    created() {
        this.state.color = 'secondary'

        if (this.formation.cancelled) {
            this.state.status = 'Annulé'
            this.state.color = 'primary darken-1'
        } else if (this.formation.closed) {
            this.state.status = 'Clôturé'
            this.state.color = 'primary darken-1'
        } else if (this.formation.quoteAccepted) {
            this.state.status = 'Devis accepté'
            this.state.color = 'primary darken-3'
        } else if (this.formation.quoteSended) {
            this.state.status = 'Devis envoyé'
        } else {
            this.state.status = 'Préparation'
            this.state.color = 'secondary lighten-2'
        }

        this.priority.label = this.formation.priorityLevelStr
        if (this.formation.priorityLevel <= 0)
            this.priority.color = '#c3c3c3'
        else if (this.formation.priorityLevel === 1)
            this.priority.color = '#a2cdcf'
        else if (this.formation.priorityLevel === 2)
            this.priority.color = '#dd7626'
        else
            this.priority.color = '#8d2626'
    },
    data: () => ({
        state: {
            color: '',
            status: '',
        },
        priority: {
            color: '',
            label: '',
        }
    }),
}
</script>