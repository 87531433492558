<template>
    <div class="text-center mt-6">
        <v-alert
            class="d-inline-block"
            color="primary"
            type="warning">
            Erreur 404: La page demandé n'a pas été trouvé sur le serveur
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "NotFound404"
}
</script>