<template>
    <v-dialog v-model="value" max-width="80%" @input="close">
        <v-card>
            <v-card-title class="headline">Liens des Feedbacks</v-card-title>
            <v-card-text>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Nom</th>
                            <th>Url</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(link, index) in links"
                            :key="index">
                            <td>{{ link.type }}</td>
                            <td>{{ link.title }}</td>
                            <td><a :href="link.href" target="_blank">{{ link.href }}</a></td>
                            <td>
                                <v-tooltip left :open-on-hover="false">
                                    <template #activator="{ on }">
                                        <v-btn @click="copyUrl($event, on, link.href)" @blur="on.blur" retain-focus-on-click>
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copié !</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Fermer</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "LinksDialog",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Array
        }
    },
    data: () => ({
       tooltip: true,
    }),
    methods: {
        close() {
            this.$emit('input', false)
        },
        copyUrl(event, on, url){
            on.click(event)
            //document.execCommand('copy')
            navigator.clipboard.writeText(url)
        },
    }
}
</script>