<template>
    <div>
        <Crud
            title="Entreprises"
            new-title="Nouvelle entreprise"
            uri-rest="companies"
            :crud-headers="crudHeaders"
            :default-item="defaultItem"
            :disabled="disabled">
            <template scope="{editedItem}">
                <TmplCompanies :edited-item="editedItem"/>
            </template>
        </Crud>
    </div>
</template>

<script>
import Crud from "@/components/crud/Crud";
import TmplCompanies from "@/components/crud/TmplCompanies";

export default {
    name: "CrudCompanies",
    props: ['disabled'],
    components: {
        TmplCompanies,
        Crud
    },
    data: () => ({
        crudHeaders: [
            {text: 'Nom', value: 'name'},
            {text: 'NPA', value: 'address.zip'},
            {text: 'Ville', value: 'address.city'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
        defaultItem: {
            name: '',
            contactEmail: '',
            address: {
                addr1: '',
                addr2: '',
                zip: 0,
                city: '',
                country: ''
            },
            siren: '',
        },
    }),
}
</script>