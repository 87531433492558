<template>
    <v-row dense>
        <v-col align-self="center">
            {{ title }}
        </v-col>
        <v-col
            v-if="feedback == null || value <= 0"
            align-self="center" class="text-center">
            <small class="accent--text">Feedback indisponible</small>
        </v-col>
        <v-col
            v-else
            align-self="center" class="text-center">
            <v-rating
                :value="valueRating"
                length="4"
                background-color="primary"
                half-increments
                readonly/>
        </v-col>
        <v-col v-if="feedback == null || value <= 0"/>
        <v-col
            v-else
            align-self="center"
            class="text-center">
            {{ value }}/4
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "HomeFeedbackRating",
    props: ['title', 'feedback'],
    computed: {
        valueRating(){
            let average = this.value;
            if(average < 0.5)
                return 0
            if(average < 1)
                return 0.5
            if(average < 1.5)
                return 1
            if(average < 2)
                return 1.5
            if(average < 2.5)
                return 2
            if(average < 3)
                return 2.5
            if(average < 3.5)
                return 3
            if(average < 3.8)
                return 3.5
            return 4
        },
        value(){
            if(this.feedback != null){
                let average = -1

                if(Array.isArray(this.feedback)){
                    if(this.feedback.length > 0) {
                        average = 0
                    }
                    this.feedback.forEach(f => {
                        average += f.averageRating
                    })

                    average = average / this.feedback.length
                }else{
                    average = this.feedback.averageRating;
                }

                return Math.round((average + Number.EPSILON) * 10) / 10
            }

            return 0
        }
    }
}
</script>