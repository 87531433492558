<template>
    <CrudCompanies :disabled="!$auth.hasAdminRights"/>
</template>

<script>
import CrudCompanies from "@/components/crud/CrudCompanies";

export default {
    name: "SettingsCompanies",
    components: {CrudCompanies}
}
</script>