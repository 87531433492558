import axios from "axios";

const optionsDefaults = {
    apiRoot: process.env.VUE_APP_API_URL,
}

const Requester = {
    install(Vue){
        Vue.prototype.$req = {
            getResp: async function (uri, onErrorShowAlert = true, withAuth = true, conf = {}){
                if(withAuth && Vue.prototype.$auth.isAuthenticated)
                    conf.headers = {
                        Authorization: 'Bearer ' + Vue.prototype.$auth.token
                    }

                return new Promise((resolve, reject) => {
                    axios
                        .get(optionsDefaults.apiRoot + '/' + uri, conf)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            if(onErrorShowAlert)
                                Vue.prototype.$modal.showError(error)

                            reject(error)
                        })
                })
            },
            get: async function(uri, onErrorShowAlert = true, withAuth = true, conf = {}){
                return new Promise((resolve, reject) => {
                    this.getResp(uri, onErrorShowAlert, withAuth, conf)
                        .then(response => resolve(response.data))
                        .catch(error => reject(error))
                })
            },

            postResp: async function(uri, data, onErrorShowAlert = true, withAuth = true, conf = {}){
                if(withAuth && Vue.prototype.$auth.isAuthenticated)
                    conf.headers = {
                        Authorization: 'Bearer ' + Vue.prototype.$auth.token
                    }

                return new Promise((resolve, reject) => {
                    axios
                        .post(optionsDefaults.apiRoot + '/' + uri, data, conf)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            if(onErrorShowAlert)
                                Vue.prototype.$modal.showError(error)

                            reject(error)
                        })
                })
            },
            post: async function(uri, data, onErrorShowAlert = true, withAuth = true, conf = {}){
                return new Promise((resolve, reject) => {
                    this.postResp(uri, data, onErrorShowAlert, withAuth, conf)
                        .then(response => resolve(response.data))
                        .catch(error => reject(error))
                })
            },

            putResp: async function(uri, data, onErrorShowAlert = true, withAuth = true, conf = {}){
                if(withAuth && Vue.prototype.$auth.isAuthenticated)
                    conf.headers = {
                        Authorization: 'Bearer ' + Vue.prototype.$auth.token
                    }

                return new Promise((resolve, reject) => {
                    axios
                        .put(optionsDefaults.apiRoot + '/' + uri, data, conf)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            if(onErrorShowAlert)
                                Vue.prototype.$modal.showError(error)

                            reject(error)
                        })
                })
            },
            put: async function(uri, data, onErrorShowAlert = true, withAuth = true, conf = {}){
                return new Promise((resolve, reject) => {
                    this.putResp(uri, data, onErrorShowAlert, withAuth, conf)
                        .then(response => resolve(response.data))
                        .catch(error => reject(error))
                })
            },

            delResp: async function(uri, onErrorShowAlert = true, withAuth = true, conf = {}){
                if(withAuth && Vue.prototype.$auth.isAuthenticated)
                    conf.headers = {
                        Authorization: 'Bearer ' + Vue.prototype.$auth.token
                    }

                return new Promise((resolve, reject) => {
                    axios
                        .delete(optionsDefaults.apiRoot + '/' + uri, conf)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            if(onErrorShowAlert)
                                Vue.prototype.$modal.showError(error)

                            reject(error)
                        })
                })
            },
            del: async function(uri, onErrorShowAlert = true, withAuth = true, conf = {}){
                return new Promise((resolve, reject) => {
                    this.delResp(uri, onErrorShowAlert, withAuth, conf)
                        .then(response => resolve(response.data))
                        .catch(error => reject(error))
                })
            },
        }
    }
}

export default Requester