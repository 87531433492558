<template>
    <CrudInstructors :disabled="!$auth.hasAdminRights"/>
</template>

<script>
import CrudInstructors from "@/components/crud/CrudInstructors";

export default {
    name: "SettingsInstructors",
    components: {CrudInstructors}
}
</script>