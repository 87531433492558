<template>
    <Feedback feedback-type="instructor" :ratings="ratings" :full-width="fullWidth">
        <template v-slot:title>
            <span>Fiche d'évaluation du formateur</span>
        </template>

        <template v-slot:afterRating="{feedback}">
            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_Formation"
                    outlined
                    label="FORMATION: Avez-vous des remarques concernant le déroulé de la formation et si oui lesquelles ?"/>
            </v-row>

            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_FormationExercices"
                    outlined
                    label="FORMATION: Avez-vous des remarques concernant les exercices et les contrôles de connaissance durant la session de formation et si oui lesquelles ?"/>
            </v-row>

            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_Aleas"
                    outlined
                    label="ALEAS: Avez-vous rencontré des aléas durant la formation et si oui, nous vous remercions de les décrire de manière très précise et exhaustive ?"/>
            </v-row>

            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_Difficultes"
                    outlined
                    label="DIFFICULTES: Avez-vous rencontré des difficultés durant la formation et si oui, nous vous remercions de les décrire de manière très précise et exhaustive."/>
            </v-row>

            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_Reclamations"
                    outlined
                    label="RECLAMATIONS: Avez-vous reçu des réclamations durant la formation et si oui, nous vous remercions de les décrire de manière très précise et exhaustive."/>
            </v-row>
        </template>
    </Feedback>
</template>

<script>
import Feedback from "@/components/feedback/Feedback";

export default {
    name: "FeedbackInstructor",
    components: {Feedback},
    props: ['fullWidth'],
    data: () => ({
        ratings: [
            {label: 'L\'accueil de l\'entreprise a-t-il été satisfaisant ?', name: 'q_AccueilCompany'},
            {label: 'L\'accueil des stagiaires a-t-il été satisfaisant ? ', name: 'q_AccueilCandidates'},
            {label: 'Les conditions matérielles (salle, projecteur, PC, parking...)', name: 'q_Conditions'},
            {label: 'La direction de la formation est-elle adaptée à leurs besoins ?', name: 'q_Direction'},
            {label: 'Les stagiaires ont-ils interagi avec enthousiasme ?', name: 'q_Candidates'},
            {label: 'De manière générale, êtes-vous satisfait de la formation ?', name: 'q_General'},
        ]
    })
}
</script>