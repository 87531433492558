<template>
    <v-col>
        <v-card>
            <v-toolbar
                flat
                color="primary"
                dark>
                <v-toolbar-title>État de la confirmation</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <div v-if="formation.confirmationSended">
                    <ul>
                        <li>Les paramètres de formation ont été validé</li>
                        <li>La confirmation a été envoyé</li>
                    </ul>
                </div>
                <div v-else-if="formation.validated">
                    <ul>
                        <li>Les paramètres de formation ont été validé</li>
                        <li>La confirmation n'a pas été envoyé</li>
                    </ul>
                </div>
                <div v-else>
                    <ul>
                        <li>Les paramètres de formation n'ont pas encore été confirmé</li>
                    </ul>
                </div>
            </v-card-text>

            <v-toolbar
                v-if="!formation.confirmationSended"
                class="mt-6"
                flat
                color="primary"
                dark>
                <v-toolbar-title>Actions</v-toolbar-title>

                <v-spacer/>
                <v-dialog
                    v-if="!formation.confirmationSended"
                    v-model="confirmationHelpDialog"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            light
                            color="accent"
                            v-bind="attrs"
                            v-on="on">
                            Aide pour l'envoi de la confirmation
                        </v-btn>
                    </template>
                    <v-card>
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>Aide pour l'envoi de la confirmation</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-list
                                subheader>
                                <v-subheader>Documents</v-subheader>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Confirmation au client</v-list-item-title>
                                        <v-list-item-subtitle><strong>3.9</strong></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Locaux équipement : client</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Convention de formation</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Livret d'accueil</v-list-item-title>
                                        <v-list-item-subtitle><strong>3.9</strong></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Programme de formation</v-list-item-title>
                                        <v-list-item-subtitle><strong>1.1</strong></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="confirmationHelpDialog = false">
                                Fermer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>

            <v-card-text
                v-if="!formation.confirmationSended"
                class="text-center">

                <v-btn
                    v-if="!formation.validated"
                    color="accent"
                    @click="validateFormation">
                    Valider les paramètres de formation
                </v-btn>
                <v-btn
                    v-else-if="!formation.confirmationSended"
                    color="accent"
                    @click="confirmationSended">
                    La confirmation a été envoyé
                </v-btn>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "SendConfirmation",
    props: {
        formation: {
            type: Object
        }
    },
    data: () => ({
        confirmationHelpDialog: false,
    }),
    methods: {
        validateFormation() {
            let formation = this.formation
            formation.validated = true
            this.$req
                .put('formations/' + this.formation.uuid, formation)
                .then(() => this.formation.validated = true)
        },
        confirmationSended() {
            let formation = this.formation
            formation.confirmationSended = true
            this.$req
                .put('formations/' + this.formation.uuid, formation)
                .then(() => {
                    this.$toast.default('Modification enregistré')
                    this.formation.confirmationSended = true
                })
        },
    },
}
</script>