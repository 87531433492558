<template>
    <v-dialog
        v-model="visible"
        width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <div class="text--primary">{{ text }}</div>
                <div class="mt-2" v-if="textError !== ''">{{ textError }}</div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <div v-if="buttons === 'ok'">
                    <v-btn
                        color="primary"
                        text
                        @click="confirm">
                        OK
                    </v-btn>
                </div>
                <div v-else-if="buttons === 'yesno'">
                    <v-btn
                        color="primary"
                        text
                        @click="confirmYes">
                        Oui
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmNo">
                        Non
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Modal from "@/plugins/modal";

export default {
    name: "AppModal",
    data() {
        return {
            visible: false,
            title: '',
            text: '',
            textError: '',
            buttons: 'ok',

            onConfirm: {},
            onConfirmYes: {},
            onConfirmNo: {},
        }
    },
    methods: {
        hide() {
            this.visible = false;
        },
        confirm() {
            if (typeof this.onConfirm === 'function') {
                this.onConfirm();
                this.hide();
            } else {
                this.hide();
            }
        },
        confirmYes() {
            if (typeof this.onConfirmYes === 'function') {
                this.onConfirmYes();
            }
            this.confirm()
        },
        confirmNo() {
            if (typeof this.onConfirmNo === 'function') {
                this.onConfirmNo();
            }
            this.confirm()
        },
        show(params) {
            this.visible = true;

            this.title = params.title;
            this.text = params.text;
            this.textError = '';
            this.buttons = params.buttons ?? 'ok';

            this.onConfirm = params.onConfirm;
            this.onConfirmYes = params.onConfirmYes;
            this.onConfirmNo = params.onConfirmNo;
        },
        showError(error){
            this.title = 'Erreur'
            this.text = 'Une erreur est survenu durant le chargement des données.'
            this.buttons = 'ok'

            if(error.response != null && error.response.data.error != null)
                this.textError = error.response.data.error
            else
                this.textError = error

            this.visible = true
        },
    },
    beforeMount() {
        Modal.EventBus.$on('show', (params) => {
            this.show(params)
        })

        Modal.EventBus.$on('showError', (error) => {
            this.showError(error)
        })
    }
}
</script>