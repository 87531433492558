<template>
    <Feedback feedback-type="financeur" :ratings="ratings" :full-width="fullWidth">
        <template v-slot:title>
            <span>Fiche d'évaluation aux financeurs</span>
        </template>

        <template v-slot:beforeRating="{feedback}">
            <div class="pa-3">
                <p>Bonjour,</p>
                <p>Vous avez accepté la participation à une session de formation en intra-entreprise
                    du {{ new Date(feedback.firstDate).toLocaleDateString() }} sur le sujet de « {{ feedback.formationName }} »
                    de la société {{ feedback.companyName }}.</p>
                <p>Dans le cadre de notre processus d’amélioration qualité, nous aimerions
                    obtenir de votre part un retour sur la manière dont la formation s’est déroulée et vos remarques.</p>
                <p>Votre aide nous permettra de nous améliorer et de satisfaire nos clients.</p>
            </div>
        </template>
        <template v-slot:afterRating="{feedback}">
            <v-row class="ma-0 pa-3">
                <v-textarea
                    v-model="feedback.q_MoreContent"
                    outlined
                    label="Avez-vous eu des retours concernant le déroulé de la formation et si oui lesquelles ?"/>
            </v-row>
        </template>
    </Feedback>
</template>

<script>
import Feedback from "@/components/feedback/Feedback";

export default {
    name: "FeedbackFinanceur",
    components: {Feedback},
    props: ['fullWidth'],
    data: () => ({
        ratings: [
            {label: 'Sur le plan administratif (devis, convention, documents administratifs, facturation …) avez-vous été satisfait de notre prestation ?', name: 'q_Administratif'},
            {label: 'De manière générale, êtes-vous satisfait de la formation ?', name: 'q_General'},
        ]
    })
}
</script>