<template>
    <v-container
        class="col-lg-8 offset-lg-2">
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1">
                    Entreprise
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="e1 > 2"
                    step="2">
                    Candidats
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="e1 > 3"
                    step="3">
                    Formation
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <SelectCompany v-model="selectedCompany"/>

                    <v-btn
                        class="float-right ma-4"
                        color="primary"
                        :disabled="selectedCompany == null"
                        @click="e1 = 2">
                        Suivant
                    </v-btn>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                    <SelectCandidates v-model="selectedCandidates"
                                      v-if="selectedCompany != null && e1 === 2"
                                      :companyUuid="selectedCompany.uuid"/>

                    <v-btn
                        color="primary"
                        class="float-right ma-4"
                        :disabled="selectedCandidates == null || selectedCandidates.length === 0"
                        @click="e1 = 3">
                        Suivant
                    </v-btn>
                </v-stepper-content>
                <v-stepper-content step="3" class="pa-0">
                    <SelectFormation v-model="formationData"/>

                    <v-btn
                        color="primary"
                        class="float-right ma-4"
                        @click="saveFormation">
                        Terminer
                    </v-btn>
                </v-stepper-content>
                <v-stepper-content
                    step="4"
                    class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"/>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>

<script>
import SelectCompany from '@/components/models/SelectCompany'
import SelectCandidates from "@/components/models/SelectCandidates";
import SelectFormation from "@/components/models/SelectFormation";

export default {
    name: "CreateFormation",
    data: () => ({
        e1: 1,

        selectedCompany: null,
        selectedCandidates: null,
        formationData: null,
    }),
    components: {
        SelectCompany,
        SelectCandidates,
        SelectFormation,
    },
    computed: {
        selectedCompanyUuid: function() {
            return this.selectedCompany.uuid
        },
    },
    methods: {
        saveFormation: async function() {
            this.e1++;
            let formationAdded = null
            await this.$req
                .post('formations', {
                    companyUuid: this.selectedCompanyUuid,
                    priorityLevel: this.formationData.priorityLevel.level,
                    desiredDate: this.formationData.desiredDate,
                    formationSeenUuid: this.formationData.formationSeen,
                    formationProposedUuid: this.formationData.formationProposed,
                })
                .then(response => formationAdded = response)

            for(let i = 0; i < this.selectedCandidates.length; i++) {
                console.log(this.selectedCandidates)
                await this.$req
                    .put('formations/' + formationAdded.uuid + '/candidates/' + this.selectedCandidates[i])
            }

            await this.$router.push({name: 'FormationDetail.InitialChips', params:{uuid: formationAdded.uuid}})
        },
        selectedCandidatesUuid: function(formationUuid) {
            let candidatesUuid = []
            for(let i = 0; i < this.selectedCandidates.length; i++) {
                candidatesUuid.push({
                    FormationUuid: formationUuid,
                    CandidateUuid: this.selectedCandidates[i]
                })
            }

            return candidatesUuid
        },
    },
}
</script>