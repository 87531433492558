<template>
    <div>
        <div
            class="d-flex align-center justify-space-between pa-2"
            v-if="$vuetify.breakpoint.mobile">
            <v-toolbar-title>
                <v-img
                    :src="require('@/assets/images/logo_formations_france.png')"
                    max-width="250px"/>
            </v-toolbar-title>

            <v-app-bar-nav-icon
                v-if="$auth.isAuthenticated || !$route.name.includes('Feedback')"
                class="mr-4"
                @click.stop="drawer = !drawer"/>

            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
                right>
                <v-list
                    nav
                    dense>
                    <v-list-item-group
                        v-if="$auth.isAuthenticated"
                        v-model="group"
                        active-class="primary--text">
                        <v-list-item :to="{name: 'Home'}" exact>
                            <v-list-item-title>Accueil</v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{name: 'Formations'}">
                            <v-list-item-title>Formation</v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{name: 'Settings.Instructors'}">
                            <v-list-item-title>Paramètres</v-list-item-title>
                        </v-list-item>

                        <v-divider/>

                        <v-list-item @click="logout">
                            <v-list-item-title>Déconnexion</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                    <v-list-item-group
                        v-else-if="!$route.name.includes('Feedback')">
                        <v-list-item :to="{name: 'Login'}">
                            <v-list-item-title>Connexion</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>
        <header
            v-else-if="$auth.isAuthenticated || !$route.name.includes('Feedback')">
            <router-link to="/" class="router-link-no-active">
                <v-img
                    class="d-block"
                    :src="require('@/assets/images/logo_formations_france.png')"
                    :max-width="$vuetify.breakpoint.mobile ? '250px' : $vuetify.breakpoint.mdOnly ? '300px' : '400px'"/>
            </router-link>

            <nav v-if="$auth.isAuthenticated">
                <router-link :to="{name: 'Home'}" class="router-link-exact">
                    <v-btn
                        text>
                        Accueil
                    </v-btn>
                </router-link>

                <router-link :to="{name: 'Formations'}" class="router-link">
                    <v-btn text>Formations</v-btn>
                </router-link>

                <router-link :to="{name: 'Settings.Instructors'}" class="router-link">
                    <v-btn text>Paramètres</v-btn>
                </router-link>

                <v-btn
                    color="primary"
                    :fab="$vuetify.breakpoint.mobile"
                    :small="$vuetify.breakpoint.mobile"
                    class="ml-10"
                    @click="logout">
                    <span v-if="!$vuetify.breakpoint.mobile">Déconnexion</span>
                    <v-icon v-else>mdi-logout</v-icon>
                </v-btn>
            </nav>
            <nav v-else>
                <router-link :to="{name: 'Login'}" class="router-link">
                    <v-btn text>Connexion</v-btn>
                </router-link>
            </nav>
        </header>
        <header v-else>
            <v-img
                class="d-block"
                :src="require('@/assets/images/logo_formations_france.png')"
                :max-width="$vuetify.breakpoint.xs ? '300px' : '400px'"/>
        </header>
    </div>
</template>

<script>
export default {
    name: 'AppNavigation',
    data: () => ({
        drawer: false,
    }),
    methods: {
        logout () {
            this.$auth.logout()
            this.$router.push({name: 'Login'})
        }
    }
};
</script>

<style scoped>
.router-link-exact button{
    border:5px solid transparent;
    padding-bottom:5px;
}
.router-link-exact.router-link-exact-active button{
    border-bottom:5px solid #dddddd;
}

.router-link, .router-link-exact{
    text-decoration: none;
}
.router-link button{
    border:5px solid transparent;
    padding-bottom:5px;
}
.router-link.router-link-active button{
    border-bottom:5px solid #dddddd;
}

header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:3px solid #eeeeee;
    padding: 0 15px;
}
header nav{
    margin-right:30px;
}
</style>