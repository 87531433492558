<template>
    <v-row>
        <v-col
            xl="10"
            offset-xl="1">
            <div
                v-if="formations == null && !loadError"
                class="text-center">
                <v-alert>
                    <v-progress-circular
                        indeterminate
                        color="primary"/>
                </v-alert>
            </div>
            <div
                v-else-if="formations == null && loadError"
                class="text-center mt-4">
                <v-alert
                    class="d-inline-block"
                    color="primary"
                    type="warning">
                    Une erreur est survenu durant le chargement des données
                </v-alert>
            </div>
            <div v-else>
                <v-row class="justify-center">
                    <v-col
                        v-for="formation in formations"
                        :key="formation.uuid"
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                        xl="4">
                        <FormationCard :formation="formation"/>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import FormationCard from "@/components/FormationCard";

export default {
    name: "FormationsGroup",
    components: {FormationCard},
    props: {
        formations: {
            type: Array
        },
        loadError: {
            type: Boolean
        }
    }
}
</script>