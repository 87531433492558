import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import CreateFormation from '@/views/formations/CreateFormation'
import Settings from "@/views/settings/Settings";
import Formations from "@/views/formations/list/Formations";
import FormationDetail from "@/views/formations/FormationDetail";
import NotFound404 from "@/views/error/NotFound404";
import FeedbackCandidate from "@/views/feedbacks/FeedbackCandidate";
import FeedbackCompany from "@/views/feedbacks/FeedbackCompany";
import FeedbackFinanceur from "@/views/feedbacks/FeedbackFinanceur";
import FeedbackInstructor from "@/views/feedbacks/FeedbackInstructor";
import Feedback from "@/views/feedbacks/Feedback";
import Checklist from "@/views/formations/details/Checklist";
import Notes from "@/views/formations/details/Notes";
import Informations from "@/views/formations/details/Informations";
import InitialChips from "@/views/formations/details/InitialChips";
import Internal from "@/views/formations/details/Internal";
import Quotation from "@/views/formations/details/Quotation";
import Confirmation from "@/views/formations/details/Confirmation";
import Feedbacks from "@/views/formations/details/Feedbacks";
import CloseFormation from "@/views/formations/details/CloseFormation";
import SettingsInstructors from "@/views/settings/SettingsInstructors";
import SettingsFormations from "@/views/settings/SettingsFormations";
import SettingsCompanies from "@/views/settings/SettingsCompanies";
import LocauxEquipMwf from "@/views/formations/details/LocauxEquipMwf";
import Documents from "@/views/formations/details/Documents";
import Synthese from "@/views/formations/details/Synthese";
import Satisfaction from "@/views/feedbacks/Satisfaction";
import FeedbackView from "@/views/formations/details/FeedbackView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/satisfaction',
        name: 'Satisfaction',
        component: Satisfaction
    },
    {
        path: '/formations',
        name: 'Formations',
        component: Formations
    },
    {
        path: '/formations/:uuid',
        name: 'FormationDetail',
        component: FormationDetail,
        children: [
            {
                path: 'checklist',
                name: 'FormationDetail.Checklist',
                component: Checklist,
            },
            {
                path: 'notes',
                name: 'FormationDetail.Notes',
                component: Notes,
            },
            {
                path: 'informations',
                name: 'FormationDetail.Informations',
                component: Informations,
            },
            {
                path: 'initial-chips',
                name: 'FormationDetail.InitialChips',
                component: InitialChips,
            },
            {
                path: 'internal',
                name: 'FormationDetail.Internal',
                component: Internal,
            },
            {
                path: 'quotation',
                name: 'FormationDetail.Quotation',
                component: Quotation,
            },
            {
                path: 'confirmation',
                name: 'FormationDetail.Confirmation',
                component: Confirmation,
            },
            {
                path: 'locaux-equip-mwf',
                name: 'FormationDetail.LocauxEquipMwf',
                component: LocauxEquipMwf,
            },
            {
                path: 'feedbacks',
                name: 'FormationDetail.Feedbacks',
                component: Feedbacks,
            },
            {
                path: 'synthese',
                name: 'FormationDetail.Synthese',
                component: Synthese,
            },
            {
                path: 'documents',
                name: 'FormationDetail.Documents',
                component: Documents,
            },
            {
                path: 'close',
                name: 'FormationDetail.Close',
                component: CloseFormation,
            },
            {
                path: 'feedback',
                name: 'FormationDetail.Feedback',
                component: FeedbackView,
                children: [
                    {
                        path: 'candidate/:key',
                        name: 'FormationDetail.Feedback.Candidate',
                        component: FeedbackCandidate,
                    },
                    {
                        path: 'instructor/:key',
                        name: 'FormationDetail.Feedback.Instructor',
                        component: FeedbackInstructor,
                    },
                    {
                        path: 'company/:key',
                        name: 'FormationDetail.Feedback.Company',
                        component: FeedbackCompany,
                    },
                    {
                        path: 'financeur/:key',
                        name: 'FormationDetail.Feedback.Financeur',
                        component: FeedbackFinanceur,
                    }
                ]
            }
        ]
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        children: [
            {
                path: 'instructors',
                name: 'Settings.Instructors',
                component: SettingsInstructors,
            },
            {
                path: 'formations',
                name: 'Settings.Formations',
                component: SettingsFormations,
            },
            {
                path: 'companies',
                name: 'Settings.Companies',
                component: SettingsCompanies,
            }
        ]
    },
    {
        path: '/formation/create',
        name: 'CreateFormation',
        component: CreateFormation
    },
    {
        path: '/feedback',
        name: 'Feedback',
        meta:{
            noAuth: true,
        },
        component: Feedback,
        children: [
            {
                path: 'candidate/:key',
                name: 'Feedback.Candidate',
                component: FeedbackCandidate,
            },
            {
                path: 'company/:key',
                name: 'Feedback.Company',
                component: FeedbackCompany,
            },
            {
                path: 'financeur/:key',
                name: 'Feedback.Financeur',
                component: FeedbackFinanceur,
            },
            {
                path: 'instructor/:key',
                name: 'Feedback.Instructor',
                component: FeedbackInstructor,
            }
        ],
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound404,
        meta:{
            noAuth: true,
        }
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.noAuth)) {
        if(to.name === 'Feedback')
            next({name: 'NotFound'})
        else
            next()
    }else {
        if (to.name !== 'Login' && !Vue.prototype.$auth.isAuthenticated)
            next({name: 'Login'})
        else
            next()
    }
})


export default router
