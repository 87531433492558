<template>
    <div>
        <h3>Formations en cours</h3>
        <small class="accent--text">
            Formations en cours ou à venir
        </small>

        <div
            v-if="formations != null && formations.length > 0"
            class="mt-3">
            <v-row class="justify-center">
                <v-col
                    v-for="formation in formations"
                    :key="formation.uuid"
                    cols="12">
                    <FormationCard
                        :formation="formation"/>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-alert type="info" color="accent" class="my-2">
                Aucune formation disponible
            </v-alert>
        </div>
    </div>
</template>

<script>
import FormationCard from "@/components/FormationCard";
export default {
    name: "FormationsOpen",
    components: {FormationCard},
    props: ['formations']
}
</script>