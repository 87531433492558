<template>
    <div>
        <v-row
            class="ma-0 mt-6">
            <v-toolbar
                color="accent"
                dark
                flat
                dense>
                Évaluations
            </v-toolbar>
        </v-row>
        <div class="text-center">
            <v-simple-table dense class="d-inline-block text-left">
                <thead>
                    <tr>
                        <td colspan="2" class="text-center"><strong>Légendes</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width:20%"><v-rating length="1" :value="1" size="15" dense readonly/></td>
                        <td>Pas du tout</td>
                    </tr>
                    <tr>
                        <td style="width:auto"><v-rating length="2" :value="2" size="15" dense readonly/></td>
                        <td>Insuffisant</td>
                    </tr>
                    <tr>
                        <td style="width:auto"><v-rating length="3" :value="3" size="15" dense readonly/></td>
                        <td>Suffisant</td>
                    </tr>
                    <tr>
                        <td style="width:auto"><v-rating length="4" :value="4" size="15" dense readonly/></td>
                        <td>Excellent</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
        <div>
            <v-row
                v-for="(rating, idxItem) in ratings"
                :key="idxItem"
                class="ma-0">
                <v-col
                    class="pb-0"
                    align-self="center"
                    cols="12"
                    md="8"
                    lg="8"
                    sm="8">
                    {{ rating.label }}
                    <span class="primary--text">*</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="4"
                    sm="4">
                    <v-rating
                        v-model="value[rating.name]"
                        class="text-center"
                        length="4"/>
                </v-col>
                <v-col v-if="idxItem < ratings.length -1">
                    <v-divider/>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "FeedbackRating",
    props: {
        value: {
            type: Object
        },
        ratings: {
            type: Array
        }
    },
}
</script>