<template>
    <v-container
        elevation="2">
        <v-col
            cols="10"
            offset="1"
            sm="6"
            offset-sm="3"
            md="4"
            offset-md="4">
            <v-row>
                <v-col cols="4"
                       offset="4">
                    <v-img :src="require('@/assets/images/logo_mwfrance.png')"/>
                </v-col>
            </v-row>

            <div
                v-if="$auth.isAuthenticated"
                class="text-center">
                <v-alert
                    color="accent"
                    dark>
                    Vous êtes déjà connecté.
                </v-alert>

                <v-btn
                    block
                    color="primary"
                    @click="logout">
                    Se déconnecter
                </v-btn>
            </div>
            <v-row v-else-if="!loginLoad">
                <v-col>
                    <v-form
                        ref="form"
                        v-model="formValid"
                        @submit.prevent=""
                        @submit="login">
                        <v-text-field
                            v-model="loginBind.username"
                            label="Nom d'utilisateur"
                            :rules="[v => !!v || 'Un identifiant est requis']"/>
                        <v-text-field
                            v-model="loginBind.password"
                            label="Mot de passe"
                            :rules="[v => !!v || 'Un mot de passe est requis']"
                            type="password"/>
                        <v-checkbox
                            v-model="rememberLogin"
                            label="Rester connecter pendant 8 heures"/>
                        <v-alert
                            v-if="rememberLogin"
                            type="info"
                            color="accent"
                            border="left"
                            colored-border>
                            La connexion sera valide durant 8 heures. Utiliser le bouton de déconnexion pour vous
                            déconnecter si nécessaire.
                        </v-alert>
                        <v-alert
                            v-else
                            type="info"
                            color="accent"
                            border="left"
                            colored-border>
                            La connexion ne sera valide que dans cet onglet.
                        </v-alert>

                        <div class="text-center">
                            <v-btn
                                :disabled="!formValid"
                                color="primary"
                                type="submit">
                                Connexion
                            </v-btn>
                        </div>
                    </v-form>
                </v-col>
            </v-row>
            <div
                v-else
                class="text-center mt-10">
                <v-progress-circular
                    indeterminate
                    color="primary"/>
            </div>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "Login",
    data: () => ({
        loginLoad: false,
        formValid: false,

        loginBind: {
            username: '',
            password: '',
        },
        rememberLogin: false,
    }),
    methods: {
        login () {
            this.$refs.form.validate()
            this.loginLoad = true

            this.$req.postResp('auth/login', this.loginBind, false, false)
                .then(response => {
                    if(response.status === 200) {
                        this.loginLoad = false
                        this.$auth.login(response.data.token, this.rememberLogin)
                        this.$toast.default('Bienvenue ' + this.$auth.displayName)
                        this.$router.push({name: 'Home'})
                    }
                })
                .catch(error => {
                    if(typeof error.response !== 'undefined' && error.response.data.error != null)
                        this.$toast.error(error.response.data.error)
                    else
                        this.$toast.error(error)

                    this.loginBind.password = ''
                    this.loginLoad = false
                })
        },
        logout () {
            this.$auth.logout()
            this.$toast.default('Vous avez été déconnecté')
        }
    },
}
</script>