<template>
    <div>
        <v-btn :to="{name: 'FormationDetail.Feedbacks'}" class="mx-3">Revenir à la liste des feedbacks</v-btn>
        <router-view :full-width="true"/>
    </div>
</template>

<script>
export default {
    name: "FeedbackView"
}
</script>

<style scoped>

</style>