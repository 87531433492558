<template>
    <v-container>
        <v-col
            md="8"
            offset-md="2">
            <v-tabs fixed-tabs class="elevation-2" disabled="true">
                <v-tab :to="{name: 'Settings.Instructors'}">Formateurs</v-tab>
                <v-tab :to="{name: 'Settings.Formations'}">Liste des formations</v-tab>
                <v-tab :to="{name: 'Settings.Companies'}">Liste des entreprises</v-tab>

                <v-tabs-items>
                    <router-view/>
                </v-tabs-items>
            </v-tabs>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "Settings",
}
</script>