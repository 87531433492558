<template>
    <div>
        <Feedback feedback-type="candidate" :ratings="ratings" :full-width="fullWidth">
            <template v-slot:title="{feedback}">
                <span>Feedback participant: <strong>{{ feedback.title }}</strong></span>
            </template>

            <template v-slot:beforeRating="{feedback}">
                <v-row
                    class="ma-0">
                    <v-toolbar
                        color="accent"
                        dark
                        flat
                        dense>
                        Comment avez-vous eu connaissance de nos formations ?
                    </v-toolbar>
                </v-row>

                <div>
                    <v-row class="ma-0 ps-3">
                        <v-radio-group
                            v-model="feedback.q_FoundFormation"
                            hide-details>
                            <v-radio value="WEBSITE" label="Sur le site Internet de MW France"/>
                            <v-radio value="EMAIL" label="Suite à une demande par email"/>
                            <v-radio value="WORDOFMOUTH" label="Par bouche à oreille"/>
                            <v-radio value="OTHER" label="Autres"/>
                        </v-radio-group>
                    </v-row>
                    <v-row
                        v-if="feedback.q_FoundFormation === 'OTHER'"
                        class="ma-0 px-3">
                        <v-text-field
                            v-model="feedback.q_FoundFormationOtherStr"
                            :rules="[rules.counter]"
                            label="Précisez:"
                            counter
                            maxlength="40"/>
                    </v-row>
                </div>
            </template>

            <template v-slot:afterRating="{feedback}">
                <v-row class="ma-0 pa-3">
                    <v-text-field
                        v-model="feedback.q_OtherFormation"
                        label="Sur quels thèmes envisagez-vous une autre formation ?"/>
                </v-row>

                <v-row class="ma-0 pa-3">
                    <v-textarea
                        v-model="feedback.q_MoreContent"
                        outlined
                        label="Si vous voulez nous en dire plus..."/>
                </v-row>
            </template>
        </Feedback>
    </div>
</template>

<script>
import Feedback from "@/components/feedback/Feedback";

export default {
    name: "FeedbackCandidate",
    components: {Feedback},
    props: ['fullWidth'],
    data: () => ({
        fdTest: null,
        ratings: [
            {label: 'L\'accueil du formateur a-t-il été satisfaisant ?', name: 'q_AccueilFormateur'},
            {label: 'L\'organisation de la prestation (durée, rythme, ...)  était-elle adaptée ?', name: 'q_Organisation'},
            {label: 'La documentation remise est-elle de la qualité souhaitée ?', name: 'q_Documentation'},
            {label: 'Le contenu de la formation est-il adapté à vos besoins ?', name: 'q_Contenu'},
            {label: 'L\'animation de la journée et les interventions ont-elles répondu à vos attentes ?', name: 'q_Animation'},
            {label: 'Pensez-vous pouvoir exploiter les connaissances transmises dans votre activité professionnelle ?', name: 'q_Exploit'},
            {label: 'Le formateur a-t-il pu répondre à vos interrogations ?', name: 'q_Interrogations'},
            {label: 'De manière générale, êtes-vous satisfait de la formation ?', name: 'q_General'},
        ],
        rules: {
            counter: value => value.length <= 40 || 'Max 40 caractères',
        },
    }),
}
</script>