<template>
    <div>
        <v-row>
            <v-col cols="6">
                <h3>Dernier feedback</h3>
                <small class="accent--text">Feedback de la dernière formation clôturé</small>
            </v-col>

            <v-col cols="6">
                <div class="text-right my-3">
                    <router-link
                        :to="{name: 'Satisfaction'}">
                        <v-btn color="primary">
                            Satisfaction
                        </v-btn>
                    </router-link>
                </div>
            </v-col>
        </v-row>

        <v-card
            v-if="formation != null"
            class="mt-1"
            shaped>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                        {{ formation.company.name }}
                    </v-list-item-title>
                    <v-list-item-content>
                        <div v-if="!formation.feedbackGenerated">
                            <v-alert>Les feedbacks n'ont pas été généré</v-alert>
                        </div>
                        <div v-else>
                            <HomeFeedbackRating title="Participants" :feedback="formation.feedbackCandidates"/>
                            <HomeFeedbackRating title="Entreprise" :feedback="formation.feedbackCompany"/>
                            <HomeFeedbackRating title="Au financeur" :feedback="formation.feedbackFinanceur"/>
                            <HomeFeedbackRating title="Formateur" :feedback="formation.feedbackInstructor"/>
                        </div>
                    </v-list-item-content>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <div v-else>
            <v-alert type="info" color="accent" class="mb-2">
                Aucun feedback disponible
            </v-alert>
        </div>
    </div>
</template>

<script>
import HomeFeedbackRating from "@/components/home/HomeFeedbackRating";

export default {
    name: "RecentFeedback",
    components: {HomeFeedbackRating},
    props: ['formation']
}
</script>