<template>
    <v-row class="align-center" no-gutters>
        <label class="v-label theme--light col">{{ label }}</label>
        <v-rating color="primary"
                  class="col-auto"
                  hover
                  length="5"
                  :value="value"
                  @input="handleInput"
                  clearable
                  empty-icon="mdi-circle-outline"
                  full-icon="mdi-circle"
                  half-icon="mdi-circle-half-full"/>
    </v-row>
</template>

<script>
export default {
    name: "FormRating",
    props: ['label', 'value'],
    methods:{
        handleInput(e){
            this.$emit('input', e)
        }
    }
}
</script>

<style scoped>

</style>