<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular
            color="primary"
            indeterminate/>
    </div>
    <div v-else-if="loadError" class="text-center mt-6">
        <v-alert
            class="d-inline-block"
            color="primary"
            type="warning">
            <span v-if="errorNotFound">Le formulaire demandé n'a pas été trouvé</span>
            <span v-else-if="errorForbid">Vous n'avez pas l'autorisation de consulter ce formulaire</span>
            <span v-else>Une erreur est survenu lors du chargement du formulaire</span>
        </v-alert>
    </div>

    <div v-else-if="feedbackValidated" class="text-center mt-6">
        <v-alert
            class="d-inline-block"
            type="info"
            color="accent">
            Votre feedback a bien été validé, merci !
        </v-alert>
    </div>

    <v-col
        v-else
        cols="12"
        :lg="fullWidth ? 12 : 6"
        :offset-lg="fullWidth ? 0 : 3"
        :xl="fullWidth ? 12 : 6"
        :offset-xl="fullWidth ? 0 : 3">

        <v-alert
            v-if="feedbackAlreadyValidated"
            class="text-center"
            type="info"
            color="accent"
            border="bottom"
            colored-border
            elevation="2">
            Ce feedback a déjà été validé, pour consultation uniquement.
        </v-alert>
        <v-card
            flat
            outlined
            :disabled="feedbackAlreadyValidated">
            <v-toolbar
                flat
                color="primary"
                dark>
                <slot name="title" :feedback="feedback"/>
            </v-toolbar>

            <v-card-text class="pa-0">
                <slot name="beforeRating" :feedback="feedback"/>

                <FeedbackRating v-if="ratings != null" :ratings="ratings" v-model="feedback"/>

                <slot name="afterRating" :feedback="feedback"/>
            </v-card-text>

            <v-card-actions class="justify-center">
                <v-btn
                    color="primary"
                    :disabled="feedbackAlreadyValidated"
                    @click="validateFeedback">
                    Valider votre feedback
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import FeedbackRating from "@/components/feedback/FeedbackRating";
export default {
    name: "Feedback",
    components: {FeedbackRating},
    props: ['feedbackType', 'ratings', 'fullWidth'],
    mounted() {
        this.$req
            .get('feedbacks/' + this.feedbackType + '/' + this.$route.params.key, false, true)
            .then(data => {
                this.feedback = data

                if (this.feedback.validated)
                    this.feedbackAlreadyValidated = true
            })
            .catch(error => {
                if (typeof (error.response) != 'undefined')
                    if (error.response.status === 404)
                        this.errorNotFound = true
                    else if(error.response.status === 403)
                        this.errorForbid = true

                this.loadError = true
            })
            .finally(() => this.loading = false)
    },
    data: () => ({
        loading: true,
        loadError: false,
        errorNotFound: false,
        errorForbid: false,

        feedback: null,
        feedbackValidated: false,
        feedbackAlreadyValidated: false,
    }),
    methods: {
        validateFeedback() {
            console.log(this.feedback)
            console.log(this.ratings)

            let valid = true
            this.ratings.forEach(rating => {
                if(this.feedback[rating.name] === -1){
                    valid = false
                }
            })

            if(!valid){
                this.$modal.show({
                    title: 'Feedback incomplet',
                    text: 'Merci de bien vouloir compléter tous les champs muni d\'un astérisque',
                })
                return
            }

            this.$toast.default('Enregistrement...')
            this.$req
                .put('feedbacks/' + this.feedbackType + '/' + this.$route.params.key,
                    this.feedback, true, false)
                .then(() => {
                    this.feedbackValidated = true
                })
        }
    }
}
</script>