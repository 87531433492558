import Vue from 'vue';
import jwt from "jsonwebtoken";

const optionsDefaults = {
    tokenName: 'mwauth_token'
}

class MwAuth {
    constructor(){
        this.VM = new Vue({
            data: () => ({
                token: null,
            })
        })

        if(sessionStorage.getItem(optionsDefaults.tokenName) != null)
            this.VM.$data.token = sessionStorage.getItem(optionsDefaults.tokenName)
        else
            this.VM.$data.token = localStorage.getItem(optionsDefaults.tokenName)
    }

    get decodedToken(){
        if(this.VM.$data.token != null){
            return jwt.decode(this.VM.$data.token)
        }

        return null
    }

    get isAuthenticated() {
        return this.token != null
    }

    get hasManagerRights(){
        return this.decodedToken.permission === "MANAGER" || this.decodedToken.permission === "ADMIN"
    }

    get hasAdminRights(){
        return this.decodedToken.permission === "ADMIN"
    }

    get displayName() {
        return this.decodedToken.displayName
    }

    get token(){
        if(this.VM.$data.token != null) {
            let decoded = jwt.decode(this.VM.$data.token)

            if(Date.now() >= decoded.exp * 1000)
                this.VM.$data.token = null
        }

        return this.VM.$data.token
    }

    login(token, remember){
        this.VM.$data.token = token

        if(remember)
            localStorage.setItem(optionsDefaults.tokenName, token)
        else
            sessionStorage.setItem(optionsDefaults.tokenName, token)
    }

    logout(){
        this.VM.$data.token = null
        localStorage.removeItem(optionsDefaults.tokenName)
        sessionStorage.removeItem(optionsDefaults.tokenName)
    }
}

const mwauth = {
    install(Vue){
        Vue.prototype.$auth = new MwAuth()
    }
}

export default mwauth